import {
  DashboardOutlined,
  DollarOutlined,
  EditOutlined,
  LogoutOutlined,
  MessageOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps, message } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { _post } from "../../../helpers/AxiosService";

export const SiderMenu = ({ onAction }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, webSettings } = useAuth();
  const items: MenuProps["items"] = [
    {
      key: "/dashboard",
      label: "Dashboard",
      icon: <DashboardOutlined />,
      onClick: () => {
        navigate("/dashboard");
        onAction();
      },
    },
    {
      type: "submenu",
      label: "Earning Task",
      icon: <DollarOutlined />,
      key: "2",
      children: [
        {
          key: "/ads",
          icon: <DollarOutlined />,
          label: "View Ads",
          onClick: () => {
            navigate("/ads");
            onAction();
          },
        },
        {
          key: "2-2",
          icon: <DollarOutlined />,
          label: "Telegram Share",
          onClick: async () => {
            try {
              await _post("/telegram-share");
              window.open(
                webSettings.telegram_share_link,
                "_blank",
                "noopener,noreferrer"
              );
              onAction();
            } catch {
              message.error("Maximum number of shares reached!");
            }
          },
        },
      ],
    },
    {
      type: "submenu",
      label: "My Network",
      icon: <DollarOutlined />,
      key: "3",
      children: [
        {
          key: "/direct-team",
          icon: <DollarOutlined />,
          label: "Direct Network",
          onClick: () => {
            navigate("/direct-team");
            onAction();
          },
        },
        {
          key: "/all-team",
          icon: <DollarOutlined />,
          label: "All Network",
          onClick: () => {
            navigate("/all-team");
            onAction();
          },
        },
      ],
    },
    {
      label: "My Earnings",
      icon: <DollarOutlined />,
      key: "4",
      onClick: () => {
        navigate("/commission-records");
        onAction();
      },
    },
    {
      type: "submenu",
      label: "Withdrawl",
      icon: <DollarOutlined />,
      key: "5",
      children: [
        {
          key: "/withdraw",
          icon: <DollarOutlined />,
          label: "Withdraw",
          onClick: () => {
            navigate("/withdraw");
            onAction();
          },
        },
        {
          key: "/withdraw-history",
          icon: <DollarOutlined />,
          label: "History",
          onClick: () => {
            navigate("/withdraw-history");
            onAction();
          },
        },
        {
          key: "/profile-banking",
          icon: <DollarOutlined />,
          label: "Banking Info",
          onClick: () => {
            navigate("/profile-banking");
            onAction();
          },
        },
      ],
    },
    {
      type: "submenu",
      label: "My Profile",
      icon: <UserOutlined />,
      key: "6",
      children: [
        {
          key: "/profile",
          icon: <UserOutlined />,
          label: "My Profile",
          onClick: () => {
            navigate("/profile");
            onAction();
          },
        },
        {
          key: "/profile-update",
          label: "Update Password",
          icon: <EditOutlined />,
          onClick: () => {
            navigate("/profile-update");
            onAction();
          },
        },
      ],
    },
    {
      key: "7",
      label: "Chat With Us",
      icon: <MessageOutlined />,
      onClick: () => {
        window.open(
          webSettings.telegram_chat_link,
          "_blank",
          "noopener,noreferrer"
        );
        onAction();
      },
    },
    {
      key: "8",
      label: "Help",
      icon: <PhoneOutlined />,
      onClick: () => {
        window.open("tel:+919050446240");
        onAction();
      },
    },
    {
      key: "8",
      label: "Logout",
      onClick: () => {
        logout();
      },
      icon: <LogoutOutlined />,
    },
  ];

  const [openKeys, setOpenKeys] = useState<string[]>([]);
  return (
    <Menu
      mode="inline"
      selectedKeys={[location.pathname + location.search]}
      openKeys={openKeys}
      onOpenChange={(e) => {
        setOpenKeys([e[e.length - 1]]);
      }}
      items={items}
    />
  );
};
