import { Flex, Typography } from "antd";
import "./index.scss";
import classNames from "classnames";
interface props {
  title: string;
  value: string;
  size?: "lg";
}

export const InfoCard = ({ title, value, size }: props) => {
  const { Text, Title } = Typography;
  return (
    <Flex
      vertical
      align="center"
      justify="center"
      className={classNames({
        "info-card": true,
        lg: size === "lg",
      })}
    >
      <Title level={4}>{value}</Title>
      <Text>{title}</Text>
    </Flex>
  );
};
