import { Flex, Input, InputNumber, Table, Typography } from "antd";
import { props } from "../../interfaces/tableProps";
import { useState } from "react";

export const TableComponent = ({
  title,
  columns,
  datasource,
  loading,
}: props) => {
  const { Text, Title } = Typography;
  const [pageSize, setPageSize] = useState(5);
  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={8}>
        <Title level={4}>{title}</Title>
        <Flex gap={4} align="center">
          <Text strong>Rows: </Text>
          <InputNumber
            size="small"
            value={pageSize}
            onChange={(e) => {
              setPageSize(e as number);
            }}
            controls={false}
          />
          <Text>/page</Text>
        </Flex>
      </Flex>
      <Table
        loading={loading}
        showSorterTooltip={false}
        columns={columns}
        dataSource={datasource}
        scroll={{
          x: 500,
        }}
        pagination={{
          size: "small",
          total: datasource.length,
          showTotal(total, range) {
            return <>{`${range[0]}-${range[1]} of ${total} items`}</>;
          },
          pageSize: pageSize || 10,
        }}
        bordered
      />
    </Flex>
  );
};
