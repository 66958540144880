import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { _post, _get } from "../../../../helpers/admin/AxiosService";
import { AxiosResponse } from "axios";

// Define TypeScript interfaces for API responses
interface Group {
  id: string;
  name: string;
  // Additional properties can be added here if needed
}

export const CreateTaskModal = ({
                                  onAction,
                                  open,
                                }: {
  onAction: (object: any) => void;
  open: boolean;
  data?: any;
}) => {
  const { Title } = Typography;
  const { useForm, Item } = Form;
  const [loading, isLoading] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]); // Initialize as empty array
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>();

  // Fetch groups from the API
  const fetchGroups = async () => {
    try {
      const res: AxiosResponse<Group[]> = await _get("/admin/groups");
      setGroups(res.data); // Use res.data directly if the response is an array
    } catch {
      message.error("Failed to fetch groups");
    }
  };

  // Fetch groups when the modal is opened
  useEffect(() => {
    if (open) {
      fetchGroups();
    }
  }, [open]);

  // Handle form submission
  const onSubmit = async (e: any) => {
    const { name, url, reward, youtube_video_url } = e;
    try {
      await _post("/admin/tasks", {
        name,
        url,
        reward,
        youtube_video_url,
        group: selectedGroup,
      });
      onAction({ action: "close-create" });
      isLoading(false);
    } catch {
      message.error("Something went wrong");
      isLoading(false);
    }
  };

  const [form] = useForm();

  return (
      <Modal closeIcon={false} footer={[]} open={open}>
        <Flex vertical gap={12}>
          <Flex justify="space-between" align="center">
            <Title level={2}>Create Task</Title>
            <Button
                type="text"
                shape="circle"
                onClick={() => {
                  onAction({ action: "close-create" });
                  onAction({ action: "set-key", value: "home" });
                  form.resetFields();
                }}
            >
              <CloseCircleOutlined
                  style={{
                    fontSize: "1.5rem",
                  }}
              />
            </Button>
          </Flex>
          <Divider />
          <Form
              autoComplete="off"
              onFinish={async (e) => {
                isLoading(true);
                await onSubmit(e);
                form.resetFields();
              }}
              size="large"
              form={form}
              layout="vertical"
          >
            <Item label={<Title level={5}>Task Name</Title>} name="name">
              <Input />
            </Item>
            <Item label={<Title level={5}>Task Link</Title>} name="url">
              <Input />
            </Item>
            <Item label={<Title level={5}>Task Reward</Title>} name="reward">
              <Input type="number" />
            </Item>
            <Item label={<Title level={5}>Youtube Link</Title>} name="youtube_video_url">
              <Input />
            </Item>
            <Item label={<Title level={5}>Select Group</Title>} name="group">
              <Select
                  value={selectedGroup}
                  onChange={(value) => setSelectedGroup(value)}
                  placeholder="Select a group"
              >
                {groups.length > 0 ? (
                    groups.map(group => (
                        <Select.Option key={group.id} value={group.id}>
                          {group.name}
                        </Select.Option>
                    ))
                ) : (
                    <Select.Option value="" disabled>
                      No groups available
                    </Select.Option>
                )}
              </Select>
            </Item>
            <Button
                onClick={() => {
                  form.submit();
                }}
                block
                type="primary"
                loading={loading}
                disabled={loading}
            >
              Add <PlusCircleOutlined />
            </Button>
          </Form>
        </Flex>
      </Modal>
  );
};
