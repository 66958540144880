import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { AuthContextType, User } from "../interfaces/authTypes";
import { _get, getUserDetails } from "../helpers/AxiosService";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [webSettings, setSettings] = useState<any>();
  const [loading, setLoading] = useState(true);

  const login = (token: string) => {
    window.localStorage.setItem("userToken", token);
    getWebsiteSettings();
    fetchData();
  };
  const logout = () => {
    window.localStorage.removeItem("userToken");
    setIsAuthenticated(false);
    setUser(undefined);
  };
  const addBalance = (amount: number) => {
    setUser((prevState: any) => {
      return { ...prevState, balance: parseFloat(prevState.balance) + amount };
    });
  };

  const getWebsiteSettings = async (): Promise<any> => {
    try {
      const response = await _get("/web-settings");
      setSettings(response.data);
    } catch (error: any) {
      throw error;
    }
  };
  const fetchData = async () => {
    setLoading(true);
    const token = window.localStorage.getItem("userToken");
    if (token) {
      try {
        const user = await getUserDetails();
        setUser(user);
        getWebsiteSettings();
        if (!isAuthenticated) setIsAuthenticated(true);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
        logout();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        loading,
        addBalance,
        setUser,
        webSettings,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
