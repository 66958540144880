import React, { CSSProperties } from 'react';

const ContactUs: React.FC = () => {
    return (
        <div style={containerStyle}>
            <h2 style={headingStyle}>Contact Us</h2>
            <div style={contentStyle}>
                <div style={sectionStyle}>
                    <h3 style={subHeadingStyle}>Mobile Numbers</h3>
                    <p style={textStyle}>9991066756</p>
                    <p style={textStyle}>7056452538</p>
                </div>
                <div style={sectionStyle}>
                    <h3 style={subHeadingStyle}>WhatsApp Us</h3>
                    <p style={textStyle}>9991066756</p>
                </div>
                <div style={sectionStyle}>
                    <h3 style={subHeadingStyle}>Address</h3>
                    <p style={textStyle}>110/111, Pace City II, Sector 37</p>
                    <p style={textStyle}>Gurugram, Haryana 122004</p>
                </div>
            </div>
        </div>
    );
}

const containerStyle: CSSProperties = {
    padding: '20px',
    maxWidth: '600px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
};

const headingStyle: CSSProperties = {
    textAlign: 'center',
    marginBottom: '20px',
};

const contentStyle: CSSProperties = {
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
};

const sectionStyle: CSSProperties = {
    marginBottom: '15px',
};

const subHeadingStyle: CSSProperties = {
    marginBottom: '5px',
    fontSize: '18px',
};

const textStyle: CSSProperties = {
    margin: '0',
    fontSize: '16px',
};

export default ContactUs;
