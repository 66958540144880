import React, { useState } from "react";
import {
  HomeOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps, message, Modal } from "antd";
import {_post} from "../../../../helpers/admin/AxiosService";

export const SiderComponent = ({
                                 onAction,
                                 selectedKey,
                               }: {
  onAction: (object: any) => void;
  selectedKey: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleUploadExcel = () => {
    setIsModalOpen(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      // Validate if the file is an Excel or CSV file
      const allowedTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel (XLSX)
        'application/vnd.ms-excel', // Excel (XLS)
        'text/csv', // CSV
      ];
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

      // Add a check for allowed file extensions
      if (
          allowedTypes.includes(selectedFile.type) ||
          ['xlsx', 'xls', 'csv'].includes(fileExtension || '')
      ) {
        setFile(selectedFile);
      } else {
        message.error("Please upload a valid Excel or CSV file");
        setFile(null);
      }
    } else {
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      message.error("Please select a file before uploading");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      await _post(`/admin/tasks/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success("Task uploaded successfully");
      setFile(null); // Reset file state after successful upload
    } catch (error) {
      message.error("Something went wrong");
    }

    setIsModalOpen(false); // Close the modal after upload
  };

  const menuItems: MenuProps["items"] = [
    {
      key: "home",
      label: "Home",
      icon: <HomeOutlined />,
      onClick: () => {
        onAction({ action: "set-key", value: "home" });
      },
    },
    {
      key: "create",
      label: "Create Task",
      icon: <PlusCircleOutlined />,
      onClick: () => {
        onAction({ action: "set-key", value: "create" });
        onAction({ action: "open-create" });
      },
    },
    {
      key: "upload-excel",
      label: "Upload Excel/CSV",
      icon: <UploadOutlined />,
      onClick: handleUploadExcel,
    },
    {
      key: "website-settings",
      label: "Website Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        onAction({ action: "set-key", value: "website-settings" });
        onAction({ action: "open-website" });
      },
    },
  ];

  return (
      <>
        <Menu items={menuItems} selectedKeys={[selectedKey]} />
        <Modal
            title="Upload Excel/CSV"
            visible={isModalOpen}
            onOk={handleUpload}
            onCancel={() => {
              setIsModalOpen(false);
              setFile(null); // Optionally clear file state when modal closes
            }}
        >
          <input type="file" onChange={handleFileChange} />
        </Modal>
      </>
  );
};
