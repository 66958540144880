import React, { useState } from 'react';
import './index.scss';
import {
  Button,
  Card,
  Col,
  Drawer,
  Flex,
  Layout,
  message,
  Row,
  Typography,
} from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  UserOutlined,
} from '@ant-design/icons';
import logo from '../../../assets/logo.jpeg';
import { SiderComponent } from './components/Sider';
import { HeaderComponent } from './components/Header';
import { CreateTaskModal } from './components/CreateTaskModal';
import { WebsiteSettingsModal } from './components/WebsiteSettingsModal';
import { HomeCard } from './components/HomeCard';
import { TableComponent } from '../../../components/TableComponent';
import { UserCols } from './helpers/UserCols';
import { TransactionCols } from './helpers/TransactionCols';
import { getRequestCols } from './helpers/RequestCols';
import { getTaskCols } from './helpers/TaskCols';
import { _delete, _get, _put } from '../../../helpers/admin/AxiosService';
import { UpdateTaskModal } from './components/UpdateTaskModal';
import { UpdateGroupModal } from './components/UpdateGroupModal';
import { getGroupCols } from './helpers/GroupCols';
import { getUserTasksCols } from './helpers/UserTasksCols';

export const AdminDashboard = () => {
  const { Sider, Content } = Layout;
  const { Text, Title } = Typography;

  const [collapsed, setCollapsed] = useState(true);
  const [zeroWidth, setZeroWidth] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [updateUserTaskOpen, setUpdateUsertaskOpen] = useState(false);
  const [websiteOpen, setWebsiteOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState('home');
  const [userId, setUserId] = useState<string | null>(null); // Added state for userId

  const [userDrawer, setUserDrawer] = useState(false);
  const [componentId, setComponentId] = useState('');

  const [updationData, setUpdationData] = useState<any>(null);

  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const updateRequest = async (id: any, status: any) => {
    const res = await _put(`/admin/withdrawals/${id}`, { status: status });
  };

  const acceptRequest = (val: any) => {
    const newData = tableData.filter((e) => e.id !== val);
    setTableData(newData);
  };

  const deleteTask = async (val: any) => {
    try {
      await _delete(`/admin/tasks/${val}`);
      const newData = tableData.filter((e) => e.id !== val);
      setTableData(newData);
      message.success('Task deleted successfully');
    } catch {
      message.error('Could not delete task!');
    }
  };

  const onAction = async (object: { action: string; value?: string | { userId?: string } }) => {
    const { action, value } = object;

    if (action === "approve-screenshot" || action === "disapprove-screenshot") {
      if (typeof value === "object" && value !== null && "id" in value) {
        // Proceed if value is an object and has an id property
        setTableData((prevTasks) => prevTasks.filter((task) => task.id !== value.id));
      } else {
        // Handle the case where value is not an object or doesn't have an id
        message.error("Invalid task data provided.");
      }
    }
    switch (action) {
      case 'set-key':
        setSelectedKey(value as string);
        return;
      case 'open-create':
        setModalOpen(true);
        return;
      case 'close-create':
        setUpdationData(null);
        setSelectedKey('home');
        setModalOpen(false);
        return;
      case 'open-website':
        setWebsiteOpen(true);
        return;
      case 'close-website':
        setWebsiteOpen(false);
        return;
      case 'open-drawer':
        setUserDrawer(true);

        if (value && typeof value === 'object') {
          console.log('userId:', value.userId);
          const userId = value.userId || null;
          setComponentId(userId ? 'user-tasks' : 'users');
          setUserId(userId);
          console.log('Set userId:', userId);
          console.log('componentId:', componentId);

          if (userId) {
            setLoading(true);
            try {
              const res = await _get(`/admin/user-tasks?user_id=${userId}`);
              setTableData(res.data);
            } catch (error) {
              console.error('Error fetching user tasks:', error);
              message.error('Failed to fetch user tasks');
            } finally {
              setLoading(false);
            }
          }
        } else {
          setComponentId(value || '');
        }
        break;
      case 'close-drawer':
        setUserDrawer(false);
        setTableData([]);
        return;
      case 'update-task':
        setUpdationData(value);
        setUserDrawer(false);
        setUpdateOpen(true);
        return;
      case 'update-group':
        setUpdationData(value);
        setUserDrawer(false);
        setUpdateUsertaskOpen(true);
        return;
      case 'close-update':
        setUpdationData(null);
        setUpdateOpen(false);
        setUpdateUsertaskOpen(false);
        return;
      case 'delete-task':
        const deleteVal: any = value as object;
        await deleteTask(deleteVal.id);
        return;
      case 'accept-request':
        const acceptVal: any = value as object;
        await updateRequest(acceptVal.id, acceptVal.status);
        acceptRequest(acceptVal.id);
        return;
      case 'reject-request':
        const rejectVal: any = value as object;
        await updateRequest(rejectVal.id, rejectVal.status);
        acceptRequest(rejectVal.id);
        return;
      case 'user-tasks':
        try {
          const res = await _get('/admin/tasks');
          setTableData(res.data);
          setLoading(false);
        } catch {
          message.error('Some error occurred');
          setLoading(false);
          onAction({ action: 'close-drawer' });
        }
        return;
    }
  };

  return (
      <Layout
          style={{
            minHeight: '100vh',
          }}
      >
        <Sider
            breakpoint="md"
            onBreakpoint={(e) => {
              if (e) {
                setCollapsed(true);
                setZeroWidth(true);
              } else {
                setZeroWidth(false);
              }
            }}
            collapsed={collapsed}
            collapsible
            trigger={null}
            collapsedWidth={zeroWidth ? 0 : 50}
            className="admin-db-sider"
        >
          <Flex align="center" justify="center" gap={8} className="sider-header">
            {!collapsed && (
                <Flex flex={1}>
                  <img src={logo} alt="" />
                </Flex>
            )}
            <Button
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
                size="small"
                type="primary"
            >
              {!collapsed ? <CaretLeftOutlined /> : <CaretRightOutlined />}
            </Button>
          </Flex>
          <SiderComponent onAction={onAction} selectedKey={selectedKey} />
        </Sider>
        <Layout
            style={{
              marginLeft: zeroWidth ? 0 : collapsed ? 50 : 200,
              transition: 'all 0.2s',
            }}
        >
          <HeaderComponent
              zero={zeroWidth}
              setZero={setZeroWidth}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
          />
          <Content className="app-content">
            <Row gutter={[12, 12]}>
              <HomeCard
                  onAction={async () => {
                    setLoading(true);
                    onAction({ action: 'open-drawer', value: 'users' });
                    try {
                      const res = await _get('/admin/users');
                      setTableData(res.data);
                      setLoading(false);
                    } catch {
                      message.error('Some error occurred');
                      setLoading(false);
                      onAction({ action: 'close-drawer' });
                    }
                  }}
                  icon={<UserOutlined />}
                  title="Users"
                  button="View all users"
              />
              <HomeCard
                  onAction={async () => {
                    setLoading(true);
                    onAction({ action: 'open-drawer', value: 'approve' });
                    try {
                      const res = await _get('/admin/withdrawals');
                      setTableData(res.data);
                      setLoading(false);
                    } catch {
                      message.error('Some error occurred');
                      setLoading(false);
                      onAction({ action: 'close-drawer' });
                    }
                  }}
                  icon={<CheckOutlined />}
                  title="Approve"
                  button="Approve withdraw requests"
              />
              <HomeCard
                  onAction={async () => {
                    setLoading(true);
                    onAction({ action: 'open-drawer', value: 'tasks' });
                    try {
                      const res = await _get('/admin/tasks');
                      setTableData(res.data);
                      setLoading(false);
                    } catch {
                      message.error('Some error occurred');
                      setLoading(false);
                      onAction({ action: 'close-drawer' });
                    }
                  }}
                  icon={<FileDoneOutlined />}
                  title="Tasks"
                  button="All Tasks"
              />
              <HomeCard
                  onAction={async () => {
                    setLoading(true);
                    onAction({ action: 'open-drawer', value: 'groups' });
                    try {
                      const res = await _get('/admin/groups');
                      setTableData(res.data);
                      setLoading(false);
                    } catch {
                      message.error('Some error occurred');
                      setLoading(false);
                      onAction({ action: 'close-drawer' });
                    }
                  }}
                  icon={<UserOutlined />}
                  title="Groups"
                  button="View all groups"
              />
              <HomeCard
                  onAction={async () => {
                    setLoading(true);
                    onAction({ action: 'open-drawer', value: 'user-tasks' });
                    try {
                      const res = await _get('/admin/user-tasks');
                      setTableData(res.data);
                      setLoading(false);
                    } catch {
                      message.error('Some error occurred');
                      setLoading(false);
                      onAction({ action: 'close-drawer' });
                    }
                  }}
                  icon={<UserOutlined />}
                  title="User Tasks"
                  button="View User Tasks"
              />
              {/* <HomeCard
              onAction={() => {
                onAction({ action: 'open-drawer', value: 'transactions' });
              }}
              icon={<HistoryOutlined />}
              title="History"
              button="View transaction history"
            /> */}
            </Row>
            <CreateTaskModal onAction={onAction} open={modalOpen} />
            <UpdateTaskModal
                data={updationData}
                onAction={onAction}
                open={updateOpen}
            />
            <UpdateGroupModal
                data={updationData}
                onAction={onAction}
                open={updateUserTaskOpen}
            />
            <WebsiteSettingsModal onAction={onAction} open={websiteOpen} />
            <Drawer
                height={'70vh'}
                closeIcon={false}
                placement="bottom"
                open={userDrawer}
                title={
                  <Flex gap={24} justify="space-between">
                    <Title
                        level={3}
                        style={{
                          textTransform: 'capitalize',
                        }}
                    >
                      {componentId}
                    </Title>
                    <Button
                        type="text"
                        shape="circle"
                        onClick={() => {
                          onAction({ action: 'close-drawer' });
                        }}
                    >
                      <CloseCircleOutlined
                          style={{
                            fontSize: '1.5rem',
                          }}
                      />
                    </Button>
                  </Flex>
                }
            >
              {(() => {
                switch (componentId) {
                  case 'users':
                    return (
                        <>
                          <TableComponent
                              loading={loading}
                              columns={UserCols({ onAction })}
                              datasource={tableData}
                              title="All Users"
                          />
                        </>
                    );
                    // case "transactions":
                    //   return (
                    //     <>
                    //       <TableComponent
                    //         columns={TransactionCols}
                    //         datasource={[]}
                    //         title="All Transactions"
                    //       />
                    //     </>
                    //   );
                  case 'approve':
                    return (
                        <>
                          <TableComponent
                              loading={loading}
                              columns={getRequestCols({ onAction })}
                              datasource={tableData}
                              title="Approve Withdrawals"
                          />
                        </>
                    );
                  case 'tasks':
                    return (
                        <>
                          <TableComponent
                              loading={loading}
                              columns={getTaskCols({ onAction })}
                              datasource={tableData}
                              title="All Tasks"
                          />
                        </>
                    );
                  case 'groups':
                    return (
                        <>
                          <TableComponent
                              loading={loading}
                              columns={getGroupCols({ onAction })}
                              datasource={tableData}
                              title="All Groups"
                          />
                        </>
                    );
                  case 'user-tasks':
                    return (
                        <>
                          <TableComponent
                              loading={loading}
                              columns={getUserTasksCols({ onAction })}
                              datasource={tableData}
                              title="All User Tasks"
                          />
                        </>
                    );
                }
              })()}
            </Drawer>
          </Content>
        </Layout>
      </Layout>
  );
};
