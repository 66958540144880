import { useEffect, useState } from "react";
import { TableComponent } from "../../components/TableComponent";
import { columns } from "../../helpers/WithdrawCols";
import { WithdrawalTableProps } from "../../interfaces/tableProps";
import { _get } from "../../helpers/AxiosService";
import { message } from "antd";

export const WithdrawalHistory = () => {
  const [data, setData] = useState<WithdrawalTableProps[]>([]);
  const [loading, isLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await _get("/withdrawals");

        setData(res.data);
        isLoading(false);
      } catch {
        message.error("Some error occured! Please refresh the page!");
      }
    };

    fetch();
  }, []);

  return (
    <TableComponent
      loading={loading}
      title="Withdrawal History"
      datasource={data}
      columns={columns}
    />
  );
};
