import { Button, Flex, Layout, Menu, Typography } from "antd";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import logo from "../../assets/logo.jpeg";
import "./index.scss";
import { useLocation, Navigate } from "react-router-dom";
import { HeaderComponent } from "./components/header";
import { DashboardComponent } from "../../views/Dashboard";
import { AdsComponent } from "../../views/Ads";
import { DirectNetworkComponent } from "../../views/DirectNetwork";
import { AllNetworkComponent } from "../../views/AllTeam";
import { CommisionRecords } from "../../views/CommissionRecords";
import { WithdrawComponent } from "../../views/Withdraw";
import { WithdrawalHistory } from "../../views/WithdrawalHistory";
import { ProfileComponent } from "../../views/Profile";
import { BankAccountComponent } from "../../views/BankAccount";
import { UpdatePasswordComponent } from "../../views/UpdatePassword";
import { SiderMenu } from "./helpers/SiderMenuItems";
import { ReactComponent as Logo } from "../../assets/footer/certified.svg";

export const LayoutWrapper = () => {
  const { Sider, Content, Footer } = Layout;
  const { Text } = Typography;

  const location = useLocation();

  const [zeroWidth, setZeroWidth] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const components: { [key: string]: React.ReactNode } = {
    "/dashboard": <DashboardComponent />,
    "/ads": <AdsComponent />,
    "/direct-team": <DirectNetworkComponent />,
    "/all-team": <AllNetworkComponent />,
    "/commission-records": <CommisionRecords />,
    "/withdraw": <WithdrawComponent />,
    "/withdraw-history": <WithdrawalHistory />,
    "/profile": <ProfileComponent />,
    "/profile-banking": <BankAccountComponent />,
    "/profile-update": <UpdatePasswordComponent />,
  };

  const currentComponent = components[location.pathname.replace(/\/$/, "")];

  return (
    <>
      {currentComponent ? (
        <Layout hasSider>
          <Sider
            className="app-sider"
            breakpoint="md"
            onBreakpoint={(e) => {
              if (e) {
                setZeroWidth(true);
                setCollapsed(true);
              } else {
                setZeroWidth(false);
              }
            }}
            collapsible
            collapsed={collapsed}
            collapsedWidth={zeroWidth ? 0 : 50}
            trigger={null}
          >
            <Flex
              align="center"
              justify="center"
              gap={8}
              className="sider-header"
            >
              {!collapsed && (
                <Flex flex={1}>
                  <img src={logo} alt="" />
                </Flex>
              )}
              {!zeroWidth && (
                <Button
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                  size="small"
                  type="text"
                >
                  <MenuOutlined />
                </Button>
              )}
            </Flex>
            <SiderMenu
              onAction={() => {
                setCollapsed(true);
              }}
            />
          </Sider>
          <Layout
            style={{
              minHeight: "100vh",
              marginLeft: zeroWidth ? 0 : 50,
            }}
          >
            <HeaderComponent
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              zero={zeroWidth}
              setZero={setZeroWidth}
            />
            <Content className="app-content">{currentComponent}</Content>
            <Footer className="app-footer">
              <Flex
                style={{
                  width: "100%",
                }}
                align="center"
                justify="space-between"
              >
                <Flex align="center" gap={24}>
                  <Flex align="center" justify="center">
                    <Logo
                      style={{
                        width: "3rem",
                        height: "3rem",
                      }}
                    />
                  </Flex>
                  Copyright &copy; {new Date().getFullYear()}
                </Flex>
                <Text>Contact for help: +91 9991066756</Text>
              </Flex>
            </Footer>
          </Layout>
        </Layout>
      ) : (
        <>404 Not Found</>
      )}
    </>
  );
};
