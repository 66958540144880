import React, { useState, useEffect } from 'react';
import './index.scss';
import { useNavigate } from "react-router-dom";

const YoutubeVideo: React.FC = () => {
    const [showFaq, setShowFaq] = useState(false); // State for showing FAQs
    const navigate = useNavigate(); // Initialize navigate

    const handleRegisterClick = () => {
        navigate('/signup/449364C5'); // Redirect to the registration page
    };

    const toggleFaq = () => {
        setShowFaq(!showFaq);
    };

    const handleTelegramClick = () => {
        window.open('https://t.me/globalspayindia', '_blank'); // Open Telegram link in a new tab
    };


    return (
        <div className="youtube-video">
            <div className="video-container">
                {/* Replace this with your YouTube video embed code */}
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/3ktXA3Qe1Vw?si=FVedt_bezsODnIcE"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
            <div className="buttons">
                <button className="register-button" onClick={handleRegisterClick}>
                    Register Now
                </button>
                <button className="telegram-button" onClick={handleTelegramClick}>
                    Join Telegram Channel
                </button>
            </div>
            <div className="faq">
                <button className="faq-button" onClick={toggleFaq}>
                    {showFaq ? 'Hide FAQs' : 'Show FAQs'}
                </button>
                {showFaq && (
                    <div className="faq-content">
                        <h3>Frequently Asked Questions</h3>
                        <div className="faq-item">
                            <strong>Question:</strong> When can I withdraw?
                            <p>Answer: You can withdraw anytime once you have earned ₹15,000.</p>
                        </div>
                        <div className="faq-item">
                            <strong>Question:</strong> Is there a fee to withdraw?
                            <p>Answer: No, there are no fees. Simply provide the bank account where you’d like your earned money to be transferred.</p>
                        </div>
                        <div className="faq-item">
                            <strong>Question:</strong> Are there any registration charges?
                            <p>Answer: There are no registration or hidden charges. It’s completely free.</p>
                        </div>
                        <div className="faq-item">
                            <strong>Question:</strong> What do I need to do?
                            <p>Answer: You’ll need to complete small tasks.</p>
                        </div>
                        <div className="faq-item">
                            <strong>Question:</strong> What type of tasks will I be doing?
                            <p>Answer: Tasks include opening a demat account, opening a bank account, watching ads, watching YouTube videos, and other similar small tasks.</p>
                        </div>
                        <div className="faq-item">
                            <strong>Question:</strong> Is this offer genuine?
                            <p>Answer: Yes, it is genuine. We do not charge you any money; instead, we pay you for your work.</p>
                        </div>
                        <div className="faq-item">
                            <strong>Question:</strong> Can I create three accounts from one phone?
                            <p>Answer: No, you can only create one account per phone.</p>
                        </div>
                        <div className="faq-item">
                            <strong>Question:</strong> How quickly will I be paid after completing a task?
                            <p>Answer: You will receive payment within 12 hours of completing the task.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default YoutubeVideo;
