import { EllipsisOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex, TableProps, message } from "antd";
import { _post } from "../../../../helpers/admin/AxiosService";

const BASE_WEB_URL = process.env.BASE_WEB_URL || 'https://backend.globalspay.org/public';

// Utility function to handle API calls
const handleApiCall = async (action: string, record: any) => {
  try {
    await _post(`/admin/task-screenshot/${action}`, { task_id: record.task_id, user_id: record.user_id });
    message.success("Task status updated successfully");
  } catch {
    message.error("Something went wrong");
  }
};

export const getUserTasksCols = ({ onAction }: { onAction: (object: any) => void }) => {
  const UserTasksCols: TableProps["columns"] = [
    {
      key: "1",
      dataIndex: "id",
      title: "ID",
      responsive: ["md"],
      width: "10%",
      ellipsis: true,
    },
    {
      key: "2",
      dataIndex: ["user", "name"],
      title: "User Name",
      ellipsis: true,
    },
    {
      key: "6",
      dataIndex: ["user", "mobile_number"],
      title: "Mobile Number",
      ellipsis: true,
    },
    {
      key: "3",
      dataIndex: ["task", "name"],
      title: "Task Name",
      ellipsis: true,
    },
    {
      key: "4",
      dataIndex: "created_at",
      title: "Date Created",
      ellipsis: true,
      render: (e) => <>{new Date(e).toLocaleDateString()}</>,
      sorter: (a, b) => {
        const aDate = new Date(a.created_at).getTime();
        const bDate = new Date(b.created_at).getTime();
        return aDate - bDate;
      },
    },
    {
      key: "5",
      dataIndex: "screenshot",
      title: "Screenshot",
      render: (screenshot: string) => (
          screenshot ? (
              <a
                  href={`${BASE_WEB_URL}/screenshots/${screenshot}`}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                <img
                    src={`${BASE_WEB_URL}/screenshots/${screenshot}`}
                    alt="Screenshot"
                    style={{ maxWidth: '150px', maxHeight: '100px', objectFit: 'cover' }}
                />
              </a>
          ) : (
              "No Screenshot"
          )
      ),
    },
    {
      key: "actions",
      title: "Actions",
      width: "8rem",
      render: (_, record) => {
        return (
            <Flex justify="end">
              <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "3",
                        label: "Approve",
                        onClick: async () => {
                          await handleApiCall("approve", record);
                          onAction({ action: "approve-screenshot", value: record });
                        },
                        icon: <CheckOutlined />,
                      },
                      {
                        key: "4",
                        label: "Disapprove",
                        onClick: async () => {
                          await handleApiCall("disapprove", record);
                          onAction({ action: "disapprove-screenshot", value: record });
                        },
                        danger: true,
                        icon: <CloseOutlined />,
                      },
                    ],
                  }}
              >
                <Button type="primary" shape="circle">
                  <EllipsisOutlined
                      style={{
                        fontSize: "1.5rem",
                      }}
                  />
                </Button>
              </Dropdown>
            </Flex>
        );
      },
    },
  ];

  return UserTasksCols;
};
