import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import { props } from "../../../components/FieldWrapper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { _post } from "../../../helpers/admin/AxiosService";

export const AdminLogin = () => {
  const { Text, Title } = Typography;
  const { useForm, Item } = Form;
  const navigate = useNavigate();
  useEffect(() => {
    const token = window.localStorage.getItem("adminToken");
    if (token) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (e: any) => {
    const { email, password } = e;
    try {
      const data = {
        email,
        password,
      };
      const res = await _post("/admin/login", data);
      if (res.data.token) {
        window.localStorage.setItem("adminToken", res.data.token);
        navigate("/admin/dashboard");
      }
    } catch (error) {
      message.error("Something went wrong");
      console.error(error);
    }
  };

  const [form] = useForm();
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Flex vertical flex={"auto"} align="center" justify="center">
        <Row
          style={{
            width: "100%",
            padding: "24px",
          }}
          align={"middle"}
          justify={"center"}
        >
          <Col xs={24} sm={18} md={12} lg={8}>
            <Card title={<Title level={4}>Admin Login</Title>}>
              <Form
                layout="vertical"
                size="large"
                form={form}
                onFinish={(e) => handleSubmit(e)}
              >
                <Item {...props("Admin ID", "email")}>
                  <Input />
                </Item>
                <Item {...props("Password", "password")}>
                  <Input.Password />
                </Item>
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Login
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Flex>
    </Layout>
  );
};
