import client_1 from "../assets/clients/client-1.webp";
import client_2 from "../assets/clients/client-2.webp";
import client_3 from "../assets/clients/client-3.webp";
import client_4 from "../assets/clients/client-4.webp";
import client_5 from "../assets/clients/client-5.webp";
import client_6 from "../assets/clients/client-6.webp";
import client_7 from "../assets/clients/client-7.webp";
import client_8 from "../assets/clients/client-8.webp";

import testimonial_1 from "../assets/testimonials/testimonial-5.webp";

import team_1 from "../assets/team/team-1.webp";
import team_2 from "../assets/team/team-2.webp";
import team_3 from "../assets/team/team-3.webp";
import team_4 from "../assets/team/team-4.webp";

export const ClientIcons = {
  client_1,
  client_2,
  client_3,
  client_4,
  client_5,
  client_6,
  client_7,
  client_8,
};

export const TestimonialImages = {
  testimonial_1,
};

export const TeamImages = {
  team_1,
  team_2,
  team_3,
  team_4,
};
