import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  message,
  Row,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import "./index.scss";
import { InfoCard } from "./components/InfoCard";
import {
  CopyOutlined,
  DollarOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { cards, sampleData } from "./helpers/cards";
import { useAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { _get, _post, getUserDetails } from "../../helpers/AxiosService";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

export const DashboardComponent = () => {
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({});

  const { user, setUser, webSettings } = useAuth();

  useEffect(() => {
    const fetch = async () => {
      try {
        const userResponse = await getUserDetails();
        setUser(userResponse);
        const resp = await _get("/stats");
        setData(resp.data);
        setLoading(false);
      } catch {
        message.error("Some error occured!");
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const navigate = useNavigate();

  const telegramShare = async () => {
    try {
      await _post("/telegram-share");
      window.open(
        webSettings.telegram_share_link,
        "_blank",
        "noopener,noreferrer"
      );
    } catch {
      message.error("Maximum number of shares reached!");
    }
  };

  return (
    <Row justify={"center"} gutter={[12, 12]}>
      {loading ? (
        <Col span={24}>
          <Skeleton />
        </Col>
      ) : (
        <>
         {/* <Col span={24}>
            <Marquee>
              <Text strong>
                You'll be paid on 31st July, 2024 in your bank account / आपको
                withdraw 31st July, 2024 को मिलेगा, तब तक आप काम कीजिये, जो भी
                रक़म होगी वो 31 को withdraw मिल जाएगा आपके बैंक अकाउंट में -
                Salary/Widrawal आपको मंथ के अंत में मिलेगा
              </Text>
            </Marquee>
          </Col>*/}
          <Col lg={8} md={14} sm={18} xs={24}>
            <Flex vertical gap={24}>
              <Card
                title={
                  <Flex align="center" gap={8} justify="space-between">
                    <Title level={4}>{user?.name}</Title>
                    <Text strong>{user?.sponsor_id}</Text>
                  </Flex>
                }
              >
                <Flex vertical gap={16}>
                  <Flex vertical gap={8}>
                    <Flex justify="space-between">
                      <Text strong>Mobile no.: </Text>
                      <Text>+91-{user?.mobile_number}</Text>
                    </Flex>
                    <Flex justify="space-between">
                      <Text strong>Status: </Text>
                      <Tag color="green-inverse">Active</Tag>
                    </Flex>
                  </Flex>
                  <Flex gap={24}>
                    <InfoCard title="My Balance" value={user?.balance as any} />
                    <InfoCard
                      title="Withdrawal"
                      value={data.total_withdrawal_amount as any}
                    />
                  </Flex>
                </Flex>
              </Card>
              <Card>
                <Flex align="center" gap={12} vertical>
                  <Button
                    type="dashed"
                    onClick={() => {
                      window.open(
                        webSettings.telegram_share_link,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    Join our telegram channel for Updates and More Info
                  </Button>
                  <Button
                    block
                    type="primary"
                    onClick={() => {
                      navigate("/ads");
                    }}
                  >
                    Start Tasks <DollarOutlined />
                  </Button>
                  <Button
                    block
                    type="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://globalspay.org/signup/${user?.sponsor_id}`
                      );
                      message.success("Link copied to clipboard!");
                    }}
                  >
                    Copy Referral Link <CopyOutlined />
                  </Button>
                  <Button
                    block
                    type="dashed"
                    onClick={async () => {
                      await telegramShare();
                    }}
                  >
                    Telegram Share <ShareAltOutlined />
                  </Button>
                </Flex>
              </Card>
            </Flex>
          </Col>
          <Col lg={16} xs={24}>
            <Row gutter={[12, 12]}>
              {cards.map((e) => {
                return (
                  <Col xs={24} sm={12} md={8}>
                    <InfoCard
                      title={e.title}
                      value={data[e.search]}
                      size="lg"
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </>
      )}
    </Row>
  );
};
