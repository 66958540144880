import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import { props } from "../../../components/FieldWrapper";
import { useState } from "react";
import { _post } from "../../../helpers/AxiosService";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordComponent = () => {
  const { Content } = Layout;
  const { Title, Text } = Typography;
  const { useForm, Item } = Form;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = useForm();
  return (
    <Layout>
      <Content>
        <Row
          style={{
            minHeight: "100vh",
            padding: "24px",
          }}
          align={"middle"}
          justify={"center"}
        >
          <Col xs={24} sm={18} md={10}>
            <Flex vertical gap={24}>
              <Title>Forgot Password</Title>
              <Card title={<Title level={4}>Enter your mobile number</Title>}>
                <Form
                  disabled={loading}
                  onFinish={async (e) => {
                    const { mobile_number } = e;
                    setLoading(true);
                    try {
                      await _post("/check-mobile", { mobile_number });
                      navigate("/otp", {
                        state: {
                          phoneNumber: mobile_number,
                          method: "forgot_password",
                        },
                      });
                    } catch (error) {
                      message.error("No such account found");
                    } finally {
                      setLoading(false);
                    }
                  }}
                  form={form}
                  size="large"
                  layout="vertical"
                >
                  <Item {...props("Mobile Number", "mobile_number")}>
                    <Input type="number" maxLength={10} />
                  </Item>
                  <Button
                    onClick={() => {
                      form.submit();
                    }}
                    type="primary"
                    block
                  >
                    Submit
                  </Button>
                </Form>
              </Card>
            </Flex>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
