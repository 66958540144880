import { AreaChartOutlined } from "@ant-design/icons";
import { Card, Col, Flex, Typography } from "antd";

interface props {
  Icon: React.ReactNode;
  title: string;
  description: string;
}

export const ContentCard = ({ Icon, title, description }: props) => {
  const { Text, Title } = Typography;
  return (
    <Card className="content-card" bordered={false} hoverable>
      <Flex gap={12} align="start">
        {Icon}
        <Flex vertical gap={2}>
          <Title level={5}>{title}</Title>
          <Text className="font-light">{description}</Text>
        </Flex>
      </Flex>
    </Card>
  );
};
