import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Typography,
  List,
} from "antd";
import { props } from "../../../../components/FieldWrapper";
import { useEffect, useState } from "react";
import { _post, _put, _get } from "../../../../helpers/admin/AxiosService";

interface User {
  id: string;
  name: string;
  mobile_number: string;
  sponsor_id: string;
  referer_id: string;
  dob: string;
  address: string;
  balance: number;
  created_at: string;
  updated_at: string;
  pivot: any;
}

// Fetch all users for search
const fetchAllUsers = async () => {
  try {
    const res = await _get('/admin/users');
    if (res && res.data) {
      return res.data;
    } else {
      throw new Error("Unexpected response structure");
    }
  } catch (error) {
    message.error("Failed to fetch all users");
    console.error("Error fetching all users:", error);
    return [];
  }
};

// Perform batch update of users for the group
const batchUpdateGroup = async (groupId: string, groupName: string, addUsers: string[], removeUsers: string[]) => {
  const payload = {
    groupId: groupId,
    groupName: groupName,
    addUsers: addUsers,
    removeUsers: removeUsers,
  };

  try {
    const res = await _post(`/admin/group/${groupId}/batch-update`, payload);
    if (res.status !== 200) {
      throw new Error(`Batch update failed with status code ${res.status}`);
    }
    console.log("Batch update successful");
  } catch (error) {
    console.error("Batch update failed:", error);
    throw error;
  }
};

export const UpdateGroupModal = ({
                                   onAction,
                                   open,
                                   data,
                                 }: {
  onAction: (object: any) => void;
  open: boolean;
  data?: any;
}) => {
  const { Title } = Typography;
  const { useForm, Item } = Form;
  const [loading, setLoading] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState<User[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [form] = useForm();

  useEffect(() => {
    if (open && data?.id) {
      form.setFieldsValue({ ...data });

      // Ensure data.users is defined and is an array before flattening
      const flatUsers = Array.isArray(data.users) ? data.users.flat() : [];
      setAssignedUsers(flatUsers);

      fetchAllUsers().then(users => setAllUsers(users));
    }
  }, [data, open]);

  // Filter users based on the search term
  const filteredUsers = () => {
    if (!searchTerm) return [];
    return allUsers.filter((user: User) => user.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  // Handle form submission
  const onSubmit = async () => {
    setLoading(true);
    try {
      // Ensure data.users is defined and is an array before flattening
      const previousAssignedUsers = Array.isArray(data.users) ? data.users.flat() : [];

      // Determine which users to add
      const addUserIds = assignedUsers
          .map((user: User) => user.id)
          .filter((id: string) => !previousAssignedUsers.some((u: User) => u.id === id));

      // Determine which users to remove
      const removeUserIds = previousAssignedUsers
          .map((user: User) => user.id)
          .filter((id: string) => !assignedUsers.some((u: User) => u.id === id));

      // Perform batch update with all users (both previous and newly assigned)
      const batchUpdateResponse = await batchUpdateGroup(data.id, form.getFieldValue('name'), [...previousAssignedUsers.map((user: User) => user.id), ...addUserIds], removeUserIds);
      console.log("Batch update response:", batchUpdateResponse);

      onAction({ action: "close-update", value: { ...data, users: assignedUsers } });
      message.success("Task and users updated successfully");
    } catch (error) {
      // Log and display detailed error information
      console.error("Failed to update group:", error);
      message.error("Failed to update group. Check console for details.");
    } finally {
      setLoading(false);
    }
  };

  // Add a user to the assigned users list
  const addUser = (user: User) => {
    if (!assignedUsers.some((u) => u.id === user.id)) {
      setAssignedUsers([...assignedUsers, user]);
    }
  };

  // Remove a user from the assigned users list
  const removeUser = (userId: string) => {
    setAssignedUsers(assignedUsers.filter((user) => user.id !== userId));
  };

  return (
      <Modal closeIcon={false} footer={[]} open={open}>
        <Flex vertical gap={12}>
          <Flex justify="space-between" align="center">
            <Title level={2}>Update Group</Title>
            <Button
                type="text"
                shape="circle"
                onClick={() => {
                  onAction({ action: "close-update" });
                  form.resetFields();
                }}
            >
              <CloseCircleOutlined style={{ fontSize: "1.5rem" }} />
            </Button>
          </Flex>
          <Divider />
          <Form
              autoComplete="off"
              onFinish={onSubmit}
              size="large"
              form={form}
              layout="vertical"
          >
            <Item {...props(<Title level={5}>Group Name</Title>, "name")}>
              <Input />
            </Item>
            <Divider />
            <Title level={5}>Manage Users</Title>
            <Flex>
              <Input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search for users"
              />
              <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  style={{ marginLeft: "8px" }}
              >
                Search
              </Button>
            </Flex>
            <List
                bordered
                dataSource={filteredUsers()}
                renderItem={(user: User) => (
                    <List.Item
                        actions={[
                          <Button
                              type="text"
                              icon={<PlusCircleOutlined />}
                              onClick={() => addUser(user)}
                          />
                        ]}
                    >
                      {user.name}({user.mobile_number})
                    </List.Item>
                )}
            />
            <Divider />
            <Title level={5}>Assigned Users</Title>
            <List
                bordered
                dataSource={assignedUsers}
                renderItem={(user: User) => (
                    <List.Item>
                      {user.name}({user.mobile_number})
                      <Button
                          type="text"
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeUser(user.id)}
                          style={{ float: "right" }}
                      />
                    </List.Item>
                )}
            />
            <Button
                onClick={() => form.submit()}
                block
                type="primary"
                loading={loading}
                disabled={loading}
            >
              Update <CheckCircleOutlined />
            </Button>
          </Form>
        </Flex>
      </Modal>
  );
};
