import { Card, Flex, Typography } from "antd";

export const TrustComponent = () => {
  const { Text, Title } = Typography;
  return (
    <Card>
      <Flex align="center" justify="center" wrap gap={12}>
        <Flex
          vertical
          align="center"
          style={{
            padding: "12px 24px",
            flexShrink: 0,
          }}
        >
          <Title>30L</Title>
          <Text>Users</Text>
        </Flex>
        <Flex
          vertical
          align="center"
          style={{
            padding: "12px 24px",
            flexShrink: 0,
          }}
        >
          <Title>1Cr+</Title>
          <Text>Transactions</Text>
        </Flex>
        <Flex
          align="center"
          vertical
          style={{
            padding: "12px 24px",
            flexShrink: 0,
          }}
        >
          <Title>40Cr+</Title>
          <Text>Paid to users</Text>
        </Flex>
      </Flex>
    </Card>
  );
};
