import { Flex, TableProps, Tag, Typography } from "antd";
import { WithdrawalTableProps } from "../interfaces/tableProps";

const { Text } = Typography;
export const columns: TableProps<WithdrawalTableProps>["columns"] = [
  {
    key: "1",
    dataIndex: "user_id",
    title: "ID",
    ellipsis: true,
    responsive: ["md"],
    width: "5%",
  },
  {
    key: "2",
    dataIndex: "amount",
    title: "Amount",
    ellipsis: true,
    sorter: (a, b) => a.amount - b.amount,
    render: (e) => <Text>Rs. {e}</Text>,
  },
  {
    key: "5",
    dataIndex: "created_at",
    title: "Request Date",
    ellipsis: true,
    render: (e) => <Text>{new Date(e).toDateString()}</Text>,
    sorter: (a, b) => {
      const aDate = new Date(a.created_at).getTime();
      const bDate = new Date(b.created_at).getTime();
      return aDate - bDate;
    },
  },
  {
    key: "6",
    dataIndex: "updated_at",
    title: "Action Date",
    ellipsis: true,
    render: (e) => <Text>{new Date(e).toDateString()}</Text>,

    sorter: (a, b) => {
      if (a.updated_at && b.updated_at) {
        const aDate = new Date(a.updated_at).getTime();
        const bDate = new Date(b.updated_at).getTime();
        return aDate - bDate;
      }
      return 0;
    },
  },
  {
    key: "7",
    dataIndex: "status",
    title: "Status",
    ellipsis: true,
    render: (e: WithdrawalTableProps["status"]) => {
      return (
        <Flex justify="end">
          <Tag
            style={{
              textTransform: "capitalize",
            }}
            color={(() => {
              switch (e) {
                case "approved":
                  return "green-inverse";
                case "declined":
                  return "red-inverse";
                case "pending":
                  return "orange-inverse";
              }
            })()}
          >
            {e}
          </Tag>
        </Flex>
      );
    },
  },
];
