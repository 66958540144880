// src/components/LoginComponent.js
import "./index.scss";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  message,
  Typography,
} from "antd";
import logo from "../../../assets/logo.jpeg";
import { props } from "../../../components/FieldWrapper";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { _post } from "../../../helpers/AxiosService";
import { ReactComponent as Logo } from "../../../assets/footer/certified.svg";
import Trus from "../../../assets/footer/images.png";
import Trus2 from "../../../assets/footer/3.avif";
import { TrustComponent } from "../../../components/Trust";

export const LoginComponent = () => {
  const { Text, Title } = Typography;
  const { useForm, Item } = Form;
  const [form] = useForm();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("userToken");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleSubmit = async (e: any) => {
    const { mobile_number, password } = e;

    if (password.length < 8) {
      message.error("Password should be minimum 8 characters");
      return;
    }

    setLoading(true);
    try {
      const data = {
        mobile_number,
        password,
      };
      const res = await _post("/login", data);
      if (res.data.token) {
        login(res.data.token);
        navigate("/dashboard");
      }
    } catch (error) {
      message.error("Invalid credentials");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="login-container">
      <Flex
        className="card-container"
        gap={48}
        align={"center"}
        justify={"center"}
      >
        <Col xs={24} sm={14} md={10} lg={8}>
          <Card>
            <Flex vertical gap={48}>
              <img className="logo" src={logo} alt="" />
              <Flex vertical gap={12}>
                <Flex vertical gap={4}>
                  <Title level={3}>Sign In</Title>
                  <Text>Enter your details to proceed to the application</Text>
                </Flex>
                <Form
                  requiredMark={"optional"}
                  size="large"
                  form={form}
                  onFinish={handleSubmit}
                  disabled={loading}
                  layout="vertical"
                >
                  <Item {...props("Mobile Number", "mobile_number")}>
                    <Input type="number" maxLength={10} />
                  </Item>
                  <Item {...props("Password", "password")}>
                    <Input.Password />
                  </Item>
                  <Button
                    onClick={() => {
                      form.submit();
                    }}
                    type="primary"
                    block
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form>
                <Flex gap={8} vertical align="center">
                  <Text>
                    Already have an account? <Link to={"/signup"}>Sign up</Link>
                  </Text>
                  <Text>
                    <Link to={"/forgot-password"}>Forgot password</Link>
                  </Text>
                </Flex>
              </Flex>
              <TrustComponent />
              <Flex align="center" justify="center" gap={12}>
                <Logo
                  style={{
                    width: "3.25rem",
                  }}
                />
                <img
                  src={Trus}
                  alt=""
                  style={{
                    width: "3.25rem",
                  }}
                />
                <img
                  src={Trus2}
                  alt=""
                  style={{
                    width: "5.5rem",
                  }}
                />
              </Flex>
            </Flex>
          </Card>
        </Col>
      </Flex>
    </Layout>
  );
};
