import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex, TableProps } from "antd";

export const getGroupCols = ({
  onAction,
}: {
  onAction: (object: any) => void;
}) => {
  const GroupCols: TableProps["columns"] = [
    {
      key: "1",
      dataIndex: "id",
      title: "Group ID",
      responsive: ["md"],
      width: "10%",
      ellipsis: true,
    },
    {
      key: "2",
      dataIndex: "name",
      title: "Name",
      ellipsis: true,
    },
    {
      key: "3",
      dataIndex: "count",
      title: "Total Members",
      ellipsis: true,
      sorter: (a, b) => a.count - b.count,
    },
    {
      key: "4",
      dataIndex: "created_at",
      title: "Date Generated",
      ellipsis: true,
      render: (e) => <>{new Date(e).toLocaleDateString()}</>,
      sorter: (a, b) => {
        const aDate = new Date(a).getTime();
        const bDate = new Date(b).getTime();
        return aDate - bDate;
      },
    },
    {
      key: "actions",
      title: "Actions",
      width: "8rem",
      render: (_, record) => {
        return (
          <Flex justify="end">
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Update",
                    onClick: () => {
                      onAction({ action: "update-group", value: record });
                    },
                  },
                 /* {
                    key: "2",
                    label: "Delete",
                    onClick: () => {
                      onAction({ action: "delete-group", value: record });
                    },
                    danger: true,
                  },*/
                ],
              }}
            >
              <Button type="primary" shape="circle">
                <EllipsisOutlined
                  style={{
                    fontSize: "1.5rem",
                  }}
                />
              </Button>
            </Dropdown>
          </Flex>
        );
      },
    },
  ];
  return GroupCols;
};
