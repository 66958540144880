import { TableProps, Tag, Typography } from "antd";
import { CommissionTableProps } from "../interfaces/tableProps";

const { Text } = Typography;

export const columns: TableProps<CommissionTableProps>["columns"] = [
  {
    key: "1",
    dataIndex: "id",
    title: "ID",
    width: "5%",
    sorter: (a, b) => a.id - b.id,
    responsive: ["md"],
    ellipsis: true,
  },
  {
    key: "2",
    dataIndex: "amount",
    title: "Amount",
    width: "35%",
    render: (e: CommissionTableProps["amount"]) => <Text>Rs. {e}</Text>,
    sorter: (a, b) => a.amount - b.amount,
    ellipsis: true,
  },
  {
    key: "3",
    dataIndex: "description",
    title: "Description",
    width: "35%",
    render: (e: CommissionTableProps["description"]) => (
      <Text>
        {(() => {
          switch (e) {
            case "telegram":
              return <Tag color="orange-inverse">Telegram</Tag>;
            case "direct":
              return <Tag color="blue-inverse">Direct</Tag>;
            case "ad":
              return <Tag color="red-inverse">Ad</Tag>;
            case "level":
              return <Tag color="green-inverse">Level</Tag>;
          }
        })()}
      </Text>
    ),
    ellipsis: true,
  },
  {
    key: "4",
    dataIndex: "created_at",
    title: "Date",
    width: "25%",
    render: (e) => <Text>{new Date(e).toLocaleDateString()}</Text>,
    ellipsis: true,
    sorter: (a, b) => {
      const aDate = new Date(a.created_at).getTime();
      const bDate = new Date(b.created_at).getTime();
      return aDate - bDate;
    },
  },
];
