import {
  Button,
  Col,
  Dropdown,
  Flex,
  FloatButton,
  Layout,
  Menu,
  MenuProps,
  Row,
  Typography,
} from "antd";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import logo from "../../assets/logo.jpeg";
import hero_2 from "../../assets/hero/hero-2.png";
import { Link } from 'react-router-dom'; // or your chosen routing library

import {
  AreaChartOutlined,
  ControlOutlined,
  ForkOutlined,
  MailOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { ContentCard } from "./components/Card";
import { useMediaQuery } from "react-responsive";
import {
  ClientIcons,
  TeamImages,
  TestimonialImages,
} from "../../helpers/Icons&Images";
import { ClientImage } from "./components/ClientImage";
import { TestimonialComponent } from "./components/TestimonialComponent";
import {
  ResponsiveOptionsClients,
  ResponsiveOptionsTestimonials,
} from "./helpers/ResponsiveCarousel";
import { TeamDisplayCard } from "./components/TeamDisplay";

import { ReactComponent as Logo } from "../../assets/footer/certified.svg";
import Trus from "../../assets/footer/images.png";
import Trus2 from "../../assets/footer/3.avif";
import { TrustComponent } from "../../components/Trust";

export interface ClientDisplay {
  image: string;
}

export interface Testimonials {
  testimonial: string;
  name: string;
  occupation: string;
}

export interface Team {
  name: string;
  role: string;
  image: string;
}

export const HomeComponent = () => {
  const { Text, Title } = Typography;
  const { Header, Content, Footer } = Layout;
  const { BackTop } = FloatButton;
  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Home",
    },
    {
      key: "2",
      label: "Download App",
      onClick: () => {
        window.location.href = "https://globalspay.org/apk/globalsnew.apk";
        },
    },
    {
      key: "3",
      label: "About",
      onClick: () => {
        navigate("/about-us");
      },
    },
    {
      key: "4",
      label: "Login",
      onClick: () => {
        navigate("/login");
      },
    },

  ];

  const clients: ClientDisplay[] = [
    {
      image: ClientIcons.client_1,
    },
    {
      image: ClientIcons.client_2,
    },
    {
      image: ClientIcons.client_3,
    },
    {
      image: ClientIcons.client_4,
    },
    {
      image: ClientIcons.client_5,
    },
    {
      image: ClientIcons.client_6,
    },
    {
      image: ClientIcons.client_7,
    },
    {
      image: ClientIcons.client_8,
    },
  ];
  const testimonials: Testimonials[] = [
    {
      testimonial: "Globalspay has been a game-changer for me. The flexibility to choose tasks based on my schedule and the straightforward payment process have made earning money easy and enjoyable. I highly recommend it to anyone looking for extra income!”",
      name: "Rahul",
      occupation: "User",
    },
    {
      testimonial: "As a client, I’ve found Globalspay to be an invaluable resource for finding reliable individuals to complete various projects. The platform is easy to use, and the quality of work has consistently met our expectations. Great service all around!”",
      name: "Navdeep",
      occupation: "Client",
    },
    {
      testimonial: "I’ve been using Globalspay for a few months now, and it’s been a fantastic experience. The range of tasks available allows me to earn money doing things I enjoy. The platform is user-friendly and the support team is always helpful.”",
      name: "Riya",
      occupation: "User",
    },
    {
      testimonial: "Globalspay’s platform is perfect for anyone looking to supplement their income. The tasks are diverse and the payment process is smooth. I appreciate the transparency and the opportunities provided by this platform.”",
      name: "Shruti",
      occupation: "User",
    },
    {
      testimonial: "The efficiency and ease of Globalspay have exceeded my expectations. I’ve successfully completed several tasks, and the timely payments have been a significant boost to my finances. I’m very satisfied with the service.”",
      name: "Lalit",
      occupation: "User",
    },
    {
      testimonial: "Globalspay offers a fantastic way to earn extra money on the side. The task variety keeps things interesting, and the payment is always prompt. It’s a great platform for anyone looking to make some additional income.”",
      name: "Sandeep",
      occupation: "User",
    },
  ];

  return (
    <Layout className="home">
      <BackTop type="primary" />
      <Header className="home-header">
        <Flex align="center" style={{ height: "100%" }}>
          <img
            src={logo}
            style={{
              width: "3rem",
              height: "3rem",
              borderRadius: "50%",
            }}
          />
          <Flex flex={1}>
            <div></div>
          </Flex>
          {isMobile ? (
            <Dropdown trigger={["click"]} menu={{ items: items }}>
              <MenuOutlined
                style={{
                  fontSize: "1.25rem",
                }}
              />
            </Dropdown>
          ) : (
            <Menu mode="horizontal" items={items} />
          )}
        </Flex>
      </Header>
      <Content className="home-content">
        <div className="home-hero">
          <Flex flex={"auto"} vertical align="center" gap={48} justify="center">
            <Title>BlackStone Aesthetics, Global Impact</Title>
            <Flex align="center" gap={24}>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Register
              </Button>
              <Button
                size="large"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>
            </Flex>
          </Flex>
        </div>
        <Row gutter={[0, 48]} className="content-container" justify={"center"}>
          <Col span={24}>
            <Flex style={{ width: "100%" }} align="center" justify="center">
              <TrustComponent />
            </Flex>
          </Col>
          <Col
            style={{
              marginRight: isMobile ? 0 : "2rem",
            }}
            md={10}
            sm={20}
            xs={22}
            className="image-container"
          >
            <img
              src={hero_2}
              alt=""
              style={{
                width: "100%",
                height: isMobile ? "14rem" : "20rem",
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
          </Col>
          <Col md={10} sm={20} xs={22}>
            <Flex vertical gap={8}>
              <Title level={3}>
What is GlobalsPay?
              </Title>
              <Title level={5} className="italic">
                Globalspay is an innovative platform that connects individuals with a variety of tasks they can complete to earn money. Our mission is to provide flexible, accessible earning opportunities for everyone, allowing users to choose tasks that fit their schedule and skills. Whether you're looking to supplement your income or find skilled individuals for your projects, Globalspay offers a seamless and rewarding experience. Join us today to start earning and explore a world of opportunities!
              </Title>
            </Flex>
          </Col>
          <Col sm={20} xs={22}>
            <Flex vertical gap={24} align="start">
              <Title level={3} className="column-header">
                SERVICES
              </Title>

              <Flex gap={24} wrap="wrap" style={{ width: "100%" }}>
                <ContentCard
                  Icon={<AreaChartOutlined style={{ fontSize: "3rem" }} />}
                  title="Content Marketing"
                  description="Content marketing focuses on creating and distributing
                        valuable, relevant, and consistent content to attract
                        and retain a clearly defined audience."
                />
                <ContentCard
                  Icon={<ControlOutlined style={{ fontSize: "3rem" }} />}
                  title="Social Media Marketing"
                  description="This involves leveraging social media platforms such as Facebook, Twitter, Instagram, LinkedIn, and others to connect with your audience"
                />
                <ContentCard
                  Icon={<ForkOutlined style={{ fontSize: "3rem" }} />}
                  title="Affiliate Marketing"
                  description="Affiliate marketing involves partnering with other businesses or individuals (affiliates) who promote your products or services in exchange for a commission for each sale"
                />
                <ContentCard
                  Icon={<MailOutlined style={{ fontSize: "3rem" }} />}
                  title="E-Mail Marketing"
                  description="Email marketing involves sending commercial messages to a group of people via email. It is often used to promote content."
                />
              </Flex>
            </Flex>
          </Col>
          <Col sm={20} xs={22}>
            <Flex vertical gap={24} align="start">
              <Title level={3} className="column-header">
                Clients
              </Title>

              <Carousel
                value={clients}
                numVisible={6}
                responsiveOptions={ResponsiveOptionsClients}
                draggable={true}
                numScroll={1}
                // autoplayInterval={3000}
                style={{
                  width: "100%",
                }}
                itemTemplate={ClientImage}
              />
            </Flex>
          </Col>
          <Col sm={20} xs={22}>
            <Flex vertical gap={24} align="start">
              <Title level={3} className="column-header">
                Testimonials
              </Title>
              {/*<Text className="font-light">*/}
              {/*  Sed tamen tempor magna labore dolore dolor sint tempor duis*/}
              {/*  magna elit veniam aliqua esse amet veniam enim export quid quid*/}
              {/*  veniam aliqua eram noster malis nulla duis fugiat culpa esse*/}
              {/*  aute nulla ipsum velit export irure minim illum fore*/}
              {/*</Text>*/}
              <Carousel
                value={testimonials}
                numVisible={3}
                numScroll={1}
                responsiveOptions={ResponsiveOptionsTestimonials}
                // autoplayInterval={3000}
                style={{
                  width: "100%",
                }}
                itemTemplate={TestimonialComponent}
              />
            </Flex>
          </Col>
          <Row justify={"center"} className="register-banner">
            <Col xs={22} sm={20}>
              <Row gutter={[0, 12]} align={"middle"} justify={"space-between"}>
                <Col sm={16} xs={24}>
                  <Flex vertical gap={12} align="start">
                    <Title level={4}>Register Now</Title>
                    <Text className="font-light">
                      Globalspay is an innovative platform that connects individuals with a variety of tasks they can complete to earn money. Become a Tasker and Boost Your Income—Register Here!
                    </Text>
                  </Flex>
                </Col>
                <Col sm={4} xs={24}>
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={() => navigate("/signup")}
                  >
                    Register Now
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col sm={20} xs={22}>

          </Col>
        </Row>
      </Content>
      <Footer>
        <Flex align="center" gap={4}>
          <Flex align="center" gap={12}>
            <Logo style={{ width: '3rem' }} />
            <img src={Trus} alt="Trusted" style={{ width: '3.25rem' }} />
            <img src={Trus2} alt="Trusted" style={{ width: '5.5rem' }} />
          </Flex>
          <Flex  align="center" gap={6}>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-condition">Terms & Conditions</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/contact-us">Contact Us</Link>
            <Link to="https://globalspay.org/apk/globalsnew.apk">Download App</Link>
          </Flex>
          <Flex align="center" justify="center">
            Copyright &copy; {new Date().getFullYear()}
          </Flex>
        </Flex>
          </Footer>
    </Layout>
  );
};
