import {
  Button,
  Card,
  Col,
  Row,
  message,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { _get, _post } from "../../helpers/AxiosService";
import { useAuth } from "../../context/AuthContext";

export const AdsComponent = () => {
  const { Title } = Typography;

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);

  const { webSettings } = useAuth();

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await _get("/tasks");
        setData(res.data);
        setLoading(false);
      } catch {
        message.error("Some error occurred");
      }
    };

    fetch();
  }, []);

  const taskCompletion = async (taskId: any) => {
    try {
      await _post(`/tasks/${taskId}/complete`);
      message.success("Task Completed successfully");
    } catch {
      message.error("Something went wrong");
    }
  };

  const handleFileChange = (taskId: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setSelectedTaskId(taskId);
    }
  };

  const handleUpload = async () => {
    if (!file || selectedTaskId === null) {
      message.error("Please select a screenshot to upload");
      return;
    }
    const formData = new FormData();
    formData.append("screenshot", file);
    formData.append("taskId", selectedTaskId.toString());

    try {
      setUploading(true);
      await _post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Screenshot uploaded successfully");
      // Remove the task from the list
      setData(prevData => prevData.filter(task => task.id !== selectedTaskId));
      setFile(null);
      setSelectedTaskId(null);
    } catch {
      message.error("Failed to upload screenshot");
    } finally {
      setUploading(false);
    }
  };

  return (
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row align="middle" gutter={12}>
            <Col>
              <Title level={4}>Daily New Tasks will be Added!</Title>
            </Col>
            <Col>
              <Button
                  type="dashed"
                  onClick={() => {
                    window.open(
                        webSettings.telegram_share_link,
                        "_blank",
                        "noopener,noreferrer"
                    );
                  }}
              >
                Join Telegram for new task updates
              </Button>
            </Col>
          </Row>
        </Col>
        {loading ? (
            <Skeleton loading />
        ) : (
            <>
              {data.length ? (
                  data.map((e) => {
                    const isCompleted = e.completed; // Assuming `completed` is a property in your task data
                    return (
                        <Col lg={8} md={12} xs={24} key={e.id}>
                          <Card title={<Title level={3}>{e.name}</Title>}>
                            <Row align="middle" style={{ marginBottom: "1rem" }}>
                              <Col>
                                <Title level={5}>Reward: {e.reward}</Title>
                              </Col>
                            </Row>
                            <Row gutter={8}>
                              <Col span={24}>
                                {e.youtube_video_url && (
                                    <iframe
                                        width="100%"
                                        height="200"
                                        src={`https://www.youtube.com/embed/${e.youtube_video_url}`}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                )}
                              </Col>
                              <Col span={24}>
                                <Button
                                    block
                                    type="primary"
                                    onClick={async () => {
                                      window.open(e.url, "_blank", "noopener,noreferrer");
                                      if (!isCompleted) {
                                        await taskCompletion(e.id);
                                      }
                                    }}
                                >
                                  {isCompleted ? "Task Completed" : "Complete Task"}
                                </Button>
                              </Col>
                              <Col span={24}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => handleFileChange(e.id, event)}
                                />
                              </Col>
                              <Col span={24}>
                                <Button
                                    type="primary"
                                    loading={uploading}
                                    onClick={handleUpload}
                                    disabled={!file || selectedTaskId !== e.id}
                                >
                                  Upload Screenshot
                                </Button>
                              </Col>
                              {isCompleted && (
                                  <Col span={24}>
                                    <Tag color="green">Completed</Tag>
                                  </Col>
                              )}
                            </Row>
                          </Card>
                        </Col>
                    );
                  })
              ) : (
                  <Col span={24}>
                    <Tag
                        style={{
                          width: "100%",
                          height: "2rem",
                        }}
                        color="red"
                    >
                      <Row align="middle" justify="center" style={{ height: "100%" }}>
                        You have already watched ads
                      </Row>
                    </Tag>
                  </Col>
              )}
            </>
        )}
      </Row>
  );
};
