import { Button, Card, Col, Form, Input, message, Row } from "antd";
import { props, RowFieldWrapper } from "../../components/FieldWrapper";
import { _post } from "../../helpers/AxiosService";
import { useState } from "react";

export const BankAccountComponent = () => {
  const { useForm, Item } = Form;
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    try {
      const res = await _post("/bank-account", { ...e });
      message.success("Account Added Successfully!");
      setLoading(false);
      form.resetFields();
    } catch {
      message.error("Please try again!");
      setLoading(false);
    }
  };
  return (
    <Row align={"middle"} justify={"center"}>
      <Col xs={24} sm={18} md={14} lg={10}>
        <Card title={"Bank Details"}>
          <Form
            onFinish={async (e) => {
              setLoading(true);
              await handleSubmit(e);
            }}
            layout="vertical"
            size={"large"}
            form={form}
            disabled={loading}
          >
            <Item {...props("Account Holder Name", "accName")}>
              <Input />
            </Item>
            <Item {...props("Account Number", "accNumber")}>
              <Input />
            </Item>
            <RowFieldWrapper
              children={[
                <Item {...props("Bank Name", "bankName")} key={"bank-name"}>
                  <Input />
                </Item>,
                <Item {...props("IFSC", "ifsc")} key={"bank-ifsc"}>
                  <Input />
                </Item>,
              ]}
            />
            <Item {...props("Branch", "bankBranch")}>
              <Input />
            </Item>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              Submit
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
