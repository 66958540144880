import { Flex, TableProps, Tag, Typography } from "antd";
import { NetworkTableProps } from "../interfaces/tableProps";

const { Text, Title } = Typography;

export const columns: TableProps<NetworkTableProps>["columns"] = [
  {
    key: "1",
    dataIndex: "referer_id",
    title: "Agent ID",
    ellipsis: true,
    width: "15rem",
  },
  {
    key: "2",
    dataIndex: "sponsor_id",
    title: "User ID",
    render: (e, _) => {
      return <Text>{e}</Text>;
    },
    ellipsis: true,
    width: "15rem",
  },
  {
    key: "3",
    dataIndex: "created_at",
    title: "Joining Date",
    render: (e, _) => {
      return <Text>{new Date(e).toLocaleDateString()}</Text>;
    },
    sorter: (a, b) => {
      let aDate = new Date(a.created_at);
      let bDate = new Date(b.created_at);

      return aDate.getTime() - bDate.getTime();
    },
    ellipsis: true,
    width: "15rem",
  },
  {
    key: "4",
    dataIndex: "status",
    title: "Status",
    render: (e: NetworkTableProps["status"], _) => {
      return (
        <Flex justify="end">
          {e !== "active" ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </Flex>
      );
    },
    sorter: (a, b) => a.status.length - b.status.length,
    ellipsis: true,
    width: "7rem",
  },
];
