import { CarouselResponsiveOption } from "primereact/carousel";

export const ResponsiveOptionsClients: CarouselResponsiveOption[] = [
  {
    breakpoint: "992px",
    numVisible: 4,
    numScroll: 1,
  },
  {
    breakpoint: "768px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "576px",
    numVisible: 1,
    numScroll: 1,
  },
];

export const ResponsiveOptionsTestimonials: CarouselResponsiveOption[] = [
  {
    breakpoint: "992px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "768px",
    numVisible: 1,
    numScroll: 1,
  },
];
