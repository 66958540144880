import { Flex, message, TableProps, Tag, Typography } from "antd";
import { TableComponent } from "../../components/TableComponent";
import { columns } from "../../helpers/NetworkCols";
import { NetworkTableProps } from "../../interfaces/tableProps";
import { _get } from "../../helpers/AxiosService";
import { useEffect, useState } from "react";

export const AllNetworkComponent = () => {
  const [data, setData] = useState<NetworkTableProps[]>([]);
  const [loading, isLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await _get("/referrals/all-levels");
        setData(res.data.all_level_referrals);
        isLoading(false);
      } catch {
        message.error("Some error occured");
      }
    };

    fetch();
  }, []);
  return (
    <TableComponent
      loading={loading}
      title="All Team"
      datasource={data}
      columns={columns}
    />
  );
};
