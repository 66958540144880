import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-us">
            <h1>About Us</h1>
            <p>Welcome to <strong>Globalspay</strong></p>

            <section>
                <h2>Who We Are</h2>
                <p>Globalspay was founded with a vision to create an inclusive platform where anyone can participate in the gig economy and be rewarded for their skills and efforts. Our team is composed of passionate professionals dedicated to providing a seamless, user-friendly experience for both taskers and clients.</p>
            </section>

            <section>
                <h2>Our Mission</h2>
                <p>Our mission is to provide a platform where people can earn money by completing tasks, ranging from simple online activities to more complex projects. We aim to offer opportunities that are accessible to everyone, regardless of their background or location, and to foster a community where hard work and talent are recognized and rewarded.</p>
            </section>

            <section>
                <h2>What We Offer</h2>
                <ul>
                    <li><strong>Diverse Task Opportunities:</strong> Our platform features a wide range of tasks that cater to various skills and interests. From surveys and data entry to creative projects and specialized tasks, there’s something for everyone.</li>
                    <li><strong>Flexible Earning Potential:</strong> Users can choose tasks that fit their schedule and earn money based on their performance. We offer a flexible way to supplement income or pursue financial goals.</li>
                    <li><strong>User-Friendly Platform:</strong> We’ve designed our platform to be intuitive and easy to use. Whether you’re a tasker or a client, navigating our system is straightforward and efficient.</li>
                    <li><strong>Secure Transactions:</strong> We prioritize the security of your personal and financial information. Our platform employs robust security measures to ensure that all transactions are safe and secure.</li>
                </ul>
            </section>

            <section>
                <h2>Our Values</h2>
                <ul>
                    <li><strong>Empowerment:</strong> We are dedicated to empowering individuals by providing opportunities to earn money and build skills.</li>
                    <li><strong>Transparency:</strong> We believe in maintaining transparency in our operations and communications. Our users have access to clear information about tasks, payments, and policies.</li>
                    <li><strong>Integrity:</strong> We uphold high standards of honesty and fairness in all our interactions, ensuring that both taskers and clients have a positive experience.</li>
                    <li><strong>Innovation:</strong> We continuously strive to improve our platform and services by incorporating user feedback and exploring new technologies.</li>
                </ul>
            </section>

            <section>
                <h2>Our Team</h2>
                <p>Our team is comprised of experts in technology, finance, and customer service who are committed to making Globalspay the best it can be. We work collaboratively to provide exceptional service and to continually enhance our platform.</p>
            </section>

            <section>
                <h2>Join Us</h2>
                <p>Whether you’re looking to earn extra money by completing tasks or seeking a reliable platform to find skilled individuals for your projects, Globalspay is here to help. Explore our platform today and discover the opportunities that await.</p>
            </section>
        </div>
    );
};

export default AboutUs;
