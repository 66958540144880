import { ConfigProvider } from "antd";
import { lightTheme } from "./theme/light";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LayoutWrapper } from "./components/LayoutWrapper";
import { LoginComponent } from "./views/Auth/Login";
import { SignupComponent } from "./views/Auth/Signup";
import { HomeComponent } from "./views/Home";
import { AdminLogin } from "./views/Admin/Login";
import { AdminDashboard } from "./views/Admin/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./context/AuthContext";
import { OTPScreen } from "./views/Auth/components/OTPScreen";
import ProtectedAdminRoute from "./components/ProtectedAdminRoute";
import ResetPasswordScreen from "./views/Auth/components/ResetPasswordScreen";
import { ForgotPasswordComponent } from "./views/Auth/components/ForgotPassword";
import YoutubeVideo from "./views/Details";
import ContactUS from "./views/ContactUs";
import AboutUs from "./views/AboutUs";
import TermsandCondition from "./views/TermsandCondition";
import PrivacyandPolicy from "./views/PrivacyandPolicy";

function App() {
  return (
    <ConfigProvider theme={lightTheme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/login" element={<LoginComponent />} />
            <Route path="/signup" element={<SignupComponent />} />
            <Route
              path="/forgot-password"
              element={<ForgotPasswordComponent />}
            />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
            <Route path="/signup/:referId" element={<SignupComponent />} />
            <Route path="/otp" element={<OTPScreen />} />
            <Route path="/details" element={<YoutubeVideo />} />
            <Route path="/contact-us" element={<ContactUS />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms-condition" element={<TermsandCondition />} />
            <Route path="/privacy-policy" element={<PrivacyandPolicy />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route
              path="/admin/dashboard"
              element={<ProtectedAdminRoute element={<AdminDashboard />} />}
            />
            <Route
              path="*"
              element={<ProtectedRoute element={<LayoutWrapper />} />}
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
