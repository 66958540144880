import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Typography } from "antd";

export const HomeCard = ({
  onAction,
  icon,
  button,
  title,
}: {
  onAction: () => void;
  icon: React.ReactNode;
  button: string;
  title: string;
}) => {
  const { Title } = Typography;
  return (
    <>
      <Col xs={24} sm={12} lg={8}>
        <Card
          title={
            <Title level={3}>
              {icon} {title}
            </Title>
          }
        >
          <Button onClick={() => onAction()} block size="large" type="primary">
            {button}
          </Button>
        </Card>
      </Col>
    </>
  );
};
