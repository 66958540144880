import { Card, Flex, Typography } from "antd";
import { Testimonials } from "..";

export const TestimonialComponent = ({
  testimonial,
  occupation,
  name,
}: Testimonials) => {
  const { Text, Title } = Typography;
  return (
    <Card
      style={{
        margin: "0 2rem",
        borderRadius: "2rem",
      }}
    >
      <Flex
        vertical
        gap={24}
        style={{
          padding: "48px 0",
        }}
        align="center"
        justify="center"
      >
        <Text className="font-light">"{testimonial}"</Text>
        <Flex vertical gap={8} align="center">
          <Flex vertical gap={1} align="center">
            <Title level={5}>{name}</Title>
            <Text className="font-light">{occupation}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
