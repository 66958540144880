import React from 'react';

const TermsAndConditions = () => {
    return (
        <div style={{ padding: '20px', lineHeight: '1.6' }}>
            <h1>Terms and Conditions</h1>
            <p><strong>Effective Date: 15 August 2024</strong></p>

            <p>
                Welcome to Globalspay.org. Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using our website [Globalspay.org] (the "Service") operated by Globalspay ("us", "we", or "our").
            </p>

            <h2>1. Acceptance of Terms</h2>
            <p>
                By accessing or using Globalspay.org, you agree to be bound by these Terms. If you do not agree to any part of these Terms, you must not use our Service.
            </p>

            <h2>2. Changes to Terms</h2>
            <p>
                We reserve the right to update or modify these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for any changes. Your continued use of the Service after any changes constitutes acceptance of the new Terms.
            </p>

            <h2>3. Services Provided</h2>
            <p>
                Globalspay.org provides services, payment processing solutions, financial consulting, etc. The details of our services are outlined on our website. We reserve the right to modify or discontinue any aspect of our services at any time.
            </p>

            <h2>4. Account Registration</h2>
            <p>
                To use certain features of our Service, you may be required to register for an account. You agree to provide accurate, complete, and current information during the registration process and to update such information to keep it accurate, complete, and current.
            </p>

            <h2>5. User Responsibilities</h2>
            <p>
                You agree to use Globalspay.org in accordance with all applicable laws and regulations. You must not:
            </p>
            <ul>
                <li>Use the Service for any unlawful purpose or in violation of any applicable laws or regulations.</li>
                <li>Attempt to gain unauthorized access to any portion of the Service or any other systems or networks connected to the Service.</li>
                <li>Interfere with or disrupt the operation of the Service or any servers or networks connected to the Service.</li>
            </ul>

            <h2>6. Intellectual Property</h2>
            <p>
                All content on Globalspay.org, including but not limited to text, graphics, logos, images, and software, is the property of Globalspay or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, or otherwise use any content from our website without our express written permission.
            </p>

            <h2>7. Third-Party Links</h2>
            <p>
                Our Service may contain links to third-party websites or services that are not owned or controlled by Globalspay. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Globalspay is not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.
            </p>

            <h2>8. Limitation of Liability</h2>
            <p>
                To the fullest extent permitted by law, Globalspay shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, resulting from:
            </p>
            <ul>
                <li>Your use of or inability to use the Service.</li>
                <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                <li>Any interruption or cessation of transmission to or from our Service.</li>
                <li>Any bugs, viruses, or the like that may be transmitted to or through our Service by any third party.</li>
            </ul>

            <h2>9. Indemnification</h2>
            <p>
                You agree to indemnify, defend, and hold harmless Globalspay, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Service, your violation of these Terms, or your violation of any rights of another party.
            </p>

            <h2>10. Governing Law</h2>
            <p>
                These Terms are governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in [Your Jurisdiction].
            </p>

            <h2>11. Contact Us</h2>
            <p>
                If you have any questions about these Terms, please contact us at:
            </p>
            <p>Globalspay <br />9991066756</p>

            <h2>12. Termination</h2>
            <p>
                We may terminate or suspend your access to our Service immediately, without prior notice or liability, for any reason, including if you breach these Terms. Upon termination, your right to use the Service will immediately cease.
            </p>

            <h2>13. Severability</h2>
            <p>
                If any provision of these Terms is found to be invalid or unenforceable by a court, the remaining provisions will continue to be in full force and effect.
            </p>

            <h2>14. Entire Agreement</h2>
            <p>
                These Terms constitute the entire agreement between you and Globalspay regarding the use of our Service and supersede all prior agreements and understandings, whether written or oral, relating to the subject matter of these Terms.
            </p>
        </div>
    );
};

export default TermsAndConditions;
