import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ProtectedRouteProps } from '../interfaces/authTypes';

const ProtectedRoute = ({ element }: ProtectedRouteProps) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  // Define paths where authentication should be bypassed
  const bypassAuthPaths = ['/details']; // Add paths that should bypass authentication

  if (loading) {
    // TODO: Loader
    return <div>Loading...</div>;
  }

  if (bypassAuthPaths.includes(location.pathname)) {
    // Bypass authentication for specified paths
    return element;
  }

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
