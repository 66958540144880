import { Button, Card, Col, Form, Input, message, Row, Typography } from "antd";
import { props } from "../../components/FieldWrapper";
import { _post } from "../../helpers/AxiosService";
import { useState } from "react";
import { AxiosError } from "axios";

export const WithdrawComponent = () => {
  const { Text } = Typography;
  const { useForm, Item } = Form;
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const handleSubmit = async (e: any) => {
    const { amount } = e;
    try {
      const res = await _post("/withdrawals", { amount });
      form.resetFields();
      message.success("Successfully raised request");
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMsg = error.response?.data?.error || "Some error occurred";
        message.error(errorMsg);
      } else {
        message.error("An unexpected error occurred");
      }
    }
  };

  const currentDate = new Date();
  const targetDate = new Date("2024-07-31");
  return (
      <Row align={"middle"} justify={"center"} gutter={[12, 12]}>
        {currentDate < targetDate ? (
            <Col span={8}>
              <Card>
                <Text>
                  Withdrawal will start from {targetDate.toLocaleDateString()}
                </Text>
              </Card>
            </Col>
        ) : (
            <Col xs={24} sm={18} md={12}>
              <Card title={"Withdraw Request"}>
                <Form
                    onFinish={async (e) => {
                      setLoading(true);
                      await handleSubmit(e);
                      setLoading(false);
                    }}
                    size="large"
                    form={form}
                    layout="vertical"
                    disabled={loading}
                >
                  <Item {...props("Amount", "amount")}>
                    <Input type="number" />
                  </Item>
                  <Button onClick={() => form.submit()} block type="primary">
                    Generate Request
                  </Button>
                </Form>
              </Card>
            </Col>
        )}
      </Row>
  );
};
