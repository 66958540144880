import { Col, FormItemProps, Row } from "antd";

export const RowFieldWrapper = ({
  children,
}: {
  children: React.ReactNode[];
}) => {
  return (
    <Row gutter={24} key={"1"}>
      {children.map((e, i) => {
        return (
          <Col xs={24} md={12} key={i}>
            {e}
          </Col>
        );
      })}
    </Row>
  );
};

export const props = (label: React.ReactNode, name: string): FormItemProps => {
  return {
    rules: [{ required: true, message: "Required field" }],
    required: true,
    name: name,
    label: label,
  };
};
