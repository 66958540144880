import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  message,
  Form,
  Layout,
  Card,
  Col,
  Typography,
} from "antd";
import { _post } from "../../../helpers/AxiosService";
import { useAuth } from "../../../context/AuthContext";
import axios from "axios";

export const OTPScreen = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const otpSentRef = useRef(false);

  useEffect(() => {
    if (state && state.phoneNumber) {
      if (!otpSentRef.current) {
        sendOtp(state.phoneNumber);
        otpSentRef.current = true;
      }
    } else {
      message.error("Phone number is required");
      navigate("/login");
    }
  }, [state, navigate]);

  const sendOtp = async (phoneNumber:string) => {
    try {
      const res = await axios.post("http://send-sms.ashusharma.in/send-sem", {
        phoneNumber,
      });
      if (res.data.otp) {
        setGeneratedOtp(res.data.otp);
        message.success("OTP sent!");
      } else {
        message.error("Wrong mobile number!");
      }
    } catch (error) {
      message.error("Please check your mobile number!");
    }
  };

  const loginOrSignup = async () => {
    try {
      if (state.method === "login") {
        const data = {
          mobile_number: state.phoneNumber,
          password: state.password,
        };
        const res = await _post("/login", data);
        if (res.data.token) {
          login(res.data.token);
          navigate("/dashboard");
        }
      } else if (state.method === "register") {
        const { name, phoneNumber, password, referer_id } = state;
        const data = { name, mobile_number: phoneNumber, password, referer_id };
        const res = await _post("/register", data);
        if (res.data.token) {
          login(res.data.token);
          navigate("/dashboard");
        }
      } else if (state.method === "forgot_password") {
        navigate("/reset-password", {
          state: {
            phoneNumber: state.phoneNumber,
          },
        });
      }
    } catch (error) {
      message.error("Something went wrong");
      console.error(error);
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    setLoading(true);
    try {
      if (otp === generatedOtp) {
        message.success("Phone number verified!");
        loginOrSignup();
      } else {
        message.error("Invalid OTP! Please re-verify.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      message.error("Something went wrong. Please try again.");
      setLoading(false);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <div id="recaptcha-container"></div>
      <Layout.Content
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "24px",
        }}
      >
        <Col xs={24} md={12} lg={8}>
          <Card
            title={
              <Typography.Title level={3}>Please Enter OTP</Typography.Title>
            }
          >
            <Form onFinish={verifyOtp} size="large" layout="vertical">
              <Form.Item label="OTP">
                <Input
                  type="number"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Item>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Verify
              </Button>
            </Form>
          </Card>
        </Col>
      </Layout.Content>
    </Layout>
  );
};

export default OTPScreen;
