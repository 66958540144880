import { Button, Flex, Layout, Typography } from "antd";
import { Props } from "../../../../interfaces/headerAndSiderProps";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

export const HeaderComponent = ({
  collapsed,
  setCollapsed,
  zero,
  setZero,
}: Props) => {
  const { Header } = Layout;
  const { Title } = Typography;
  return (
    <Header className="app-header">
      <Flex gap={8} align="center">
        {collapsed && zero && (
          <Button
            onClick={() => {
              setCollapsed && setCollapsed(!collapsed);
            }}
            size="small"
            type="primary"
          >
            {!collapsed ? <CaretLeftOutlined /> : <CaretRightOutlined />}
          </Button>
        )}
        <Title level={3}>GlobalsPay Admin</Title>
      </Flex>
    </Header>
  );
};
