import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { _post } from "../../../helpers/AxiosService";
import { useLocation, useNavigate } from "react-router-dom";
import { props } from "../../../components/FieldWrapper";

const ResetPasswordScreen = () => {
  const { Content } = Layout;
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { useForm, Item } = Form;
  const [form] = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/forgot-password");
    }
  }, []);
  return (
    <Layout>
      <Content>
        <Row
          style={{
            minHeight: "100vh",
            padding: "24px",
          }}
        >
          <Col xs={24} sm={18} md={10}>
            <Flex vertical gap={24}>
              <Title>Reset Password</Title>
              <Card title={<Title level={4}>Enter your mobile number</Title>}>
                <Form
                  disabled={loading}
                  onFinish={async (e) => {
                    setLoading(true);
                    const { new_pass, cnf_pass } = e;
                    try {
                      if (new_pass !== cnf_pass) {
                        setLoading(false);
                        throw new Error();
                      } else {
                        const mobNo = state.phoneNumber;
                        try {
                          const resp = await _post("/reset-password", {
                            mobile_number: mobNo,
                            password: cnf_pass,
                          });
                          navigate("/login");
                        } catch {
                          message.error("Some error occured!");
                        } finally {
                          setLoading(false);
                        }
                      }
                    } catch {
                      message.error("Passwords do not match!");
                    }
                  }}
                  form={form}
                  size="large"
                  layout="vertical"
                >
                  <Item {...props("New Password", "new_pass")}>
                    <Input.Password />
                  </Item>
                  <Item {...props("Confirm Password", "cnf_pass")}>
                    <Input.Password />
                  </Item>
                  <Button
                    onClick={() => {
                      form.submit();
                    }}
                    type="primary"
                    block
                  >
                    Submit
                  </Button>
                </Form>
              </Card>
            </Flex>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ResetPasswordScreen;
