import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Typography,
} from "antd";
import { props, RowFieldWrapper } from "../../../../components/FieldWrapper";
import { useState } from "react";
import { _post } from "../../../../helpers/admin/AxiosService";

export const WebsiteSettingsModal = ({
  onAction,
  open,
}: {
  onAction: (object: any) => void;
  open: boolean;
}) => {
  const { Title, Text } = Typography;
  const { useForm, Item } = Form;
  const [loading, isLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    try {
      const resp = await _post("/web-settings", e);
      isLoading(false);
    } catch {
      message.error("Some error occurred!");
      isLoading(false);
    }
  };

  const [form] = useForm();
  return (
    <Modal closeIcon={false} footer={[]} open={open}>
      <Flex vertical gap={12}>
        <Flex justify="space-between" align="center">
          <Title level={2}>Update Website</Title>
          <Button
            type="text"
            shape="circle"
            onClick={() => {
              onAction({ action: "close-website" });
              onAction({ action: "set-key", value: "home" });
              form.resetFields();
            }}
          >
            <CloseCircleOutlined
              style={{
                fontSize: "1.5rem",
              }}
            />
          </Button>
        </Flex>
        <Divider />
        <Form
          autoComplete="off"
          onFinish={async (e) => {
            await handleSubmit(e);
            onAction({ action: "close-website" });
            onAction({ action: "set-key", value: "home" });
            form.resetFields();
          }}
          size="large"
          form={form}
          layout="vertical"
        >
          <RowFieldWrapper
            children={[
              <Item
                {...props(
                  <Title level={5}>Telegram Link</Title>,
                  "telegram_share_link"
                )}
                key={"tl"}
              >
                <Input />
              </Item>,
              <Item
                {...props(
                  <Title level={5}>Telegram Support Link</Title>,
                  "telegram_chat_link"
                )}
                key={"ts"}
              >
                <Input />
              </Item>,
            ]}
          />
          <RowFieldWrapper
            children={[
              <Item
                {...props(<Title level={5}>Refer Bonus</Title>, "refer_bonus")}
                key={"1"}
              >
                <Input type="number" />
              </Item>,
              <Item
                {...props(
                  <Title level={5}>Level Percentage</Title>,
                  "level_percentage"
                )}
                key={"2"}
              >
                <Input type="number" />
              </Item>,
            ]}
          />
          <Button
            onClick={() => {
              form.submit();
            }}
            block
            type="primary"
          >
            Update <CheckCircleOutlined />
          </Button>
        </Form>
      </Flex>
    </Modal>
  );
};
