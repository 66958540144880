import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import { RowFieldWrapper } from "../../components/FieldWrapper";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import { _post } from "../../helpers/AxiosService";
import { useEffect, useState } from "react";

export const ProfileComponent = () => {
  const { useForm, Item } = Form;
  const [form] = useForm();
  const { Text, Title } = Typography;
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [dt, setDt] = useState("");

  const disableDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  const updateProfile = async (e: any) => {
    let body = { ...e, dob: dt };
    try {
      const res = await _post("/update-profile", body);
      message.success("Update Successful");
      setLoading(false);
    } catch {
      message.error("Something went wrong! Please try again!");
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(user?.dob);
  }, []);

  return (
    <Row align={"middle"} justify={"center"}>
      <Col xs={24} sm={20} md={18} lg={12}>
        <Card title={<Title level={4}>Personal Details</Title>}>
          <Form
            initialValues={{
              ...user,
              dob: moment(user?.dob, "YYYY-MM-DD"),
            }}
            layout="vertical"
            form={form}
            size="large"
            onFinish={async (e) => {
              setLoading(true);
              await updateProfile(e);
            }}
            disabled={loading}
          >
            <RowFieldWrapper
              children={[
                <Item key={"user-id"} name={"sponsor_id"} label={"User ID"}>
                  <Input disabled />
                </Item>,
                <Item key={"agent-id"} name={"referer_id"} label={"Sponsor ID"}>
                  <Input disabled />
                </Item>,
              ]}
            />
            <Item name={"name"} label={"Name"}>
              <Input disabled />
            </Item>
            <Item name={"dob"} label={"DOB"}>
              <DatePicker
                disabledDate={disableDate}
                onChange={(_, dte) => {
                  setDt(dte as string);
                }}
                style={{
                  width: "100%",
                }}
              />
            </Item>
            <Item name={"address"} label={"Address"}>
              <Input.TextArea
                rows={2}
                style={{
                  resize: "none",
                }}
              />
            </Item>
            <RowFieldWrapper
              children={[
                <Item name={"mobile_number"} label={"Mobile No."}>
                  <Input
                    disabled
                    type="number"
                    style={{
                      width: "100%",
                    }}
                  />
                </Item>,
              ]}
            />
            <Button type="primary" size="large" onClick={() => form.submit()}>
              Submit
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
