import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '20px', lineHeight: '1.6' }}>
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date: 24 August 2024</strong></p>

            <p>
                Globalspay ("us", "we", or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [Globalspay.org] (the "Site") and use our services. Please read this policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the Site.
            </p>

            <h2>1. Information We Collect</h2>
            <p>
                We collect information from you in various ways:
            </p>
            <ul>
                <li><strong>Personal Information:</strong> When you register for an account, contact us, or use our services, we may collect personal information such as your name, email address, phone number, and payment details.</li>
                <li><strong>Usage Data:</strong> We collect information about your interaction with our Site, including your IP address, browser type, pages viewed, and the date and time of your visit.</li>
                <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience on our Site. Cookies are small data files stored on your device that help us understand your preferences and improve our services. You can control cookie settings through your browser.</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>
                We use your information for various purposes, including:
            </p>
            <ul>
                <li><strong>To Provide and Maintain Our Services:</strong> We use your information to deliver and improve our services, process transactions, and respond to your inquiries.</li>
                <li><strong>To Communicate with You:</strong> We may use your contact information to send you updates, newsletters, and promotional materials related to our services. You can opt out of receiving these communications at any time.</li>
                <li><strong>To Analyze and Improve Our Site:</strong> We analyze usage data to understand how our Site is used and to enhance its functionality and user experience.</li>
                <li><strong>To Comply with Legal Obligations:</strong> We may use your information to comply with applicable laws, regulations, and legal processes.</li>
            </ul>

            <h2>3. How We Share Your Information</h2>
            <p>
                We do not sell or rent your personal information to third parties. We may share your information in the following situations:
            </p>
            <ul>
                <li><strong>With Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our Site, processing transactions, or providing other services on our behalf. These service providers are contractually obligated to protect your information and use it only for the purposes for which it was disclosed.</li>
                <li><strong>For Legal Reasons:</strong> We may disclose your information if required to do so by law or in response to legal requests or to protect our rights, property, or safety.</li>
                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or other business transaction, your information may be transferred as part of the transaction.</li>
            </ul>

            <h2>4. Security of Your Information</h2>
            <p>
                We implement reasonable technical and organizational measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.
            </p>

            <h2>5. Your Rights and Choices</h2>
            <p>
                You have certain rights regarding your information:
            </p>
            <ul>
                <li><strong>Access and Update:</strong> You may access and update your personal information by logging into your account or contacting us directly.</li>
                <li><strong>Opt-Out:</strong> You can opt out of receiving marketing communications from us by following the unsubscribe instructions included in those communications.</li>
                <li><strong>Data Protection Rights:</strong> Depending on your location, you may have additional rights under applicable data protection laws, such as the right to access, correct, or delete your personal information.</li>
            </ul>

            <h2>6. Third-Party Links</h2>
            <p>
                Our Site may contain links to third-party websites or services. This Privacy Policy does not apply to those third-party sites, and we are not responsible for their privacy practices. We encourage you to review the privacy policies of any third-party sites you visit.
            </p>

            <h2>7. Children's Privacy</h2>
            <p>
                Our Site is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.
            </p>

            <h2>8. Changes to This Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on our Site and updating the effective date. Your continued use of the Site after any changes constitutes your acceptance of the updated Privacy Policy.
            </p>

            <h2>9. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
            </p>
            <p>Globalspay <br />9991066756</p>
        </div>
    );
};

export default PrivacyPolicy;
