import { EllipsisOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Flex,
  TableColumnProps,
  TableProps,
  Typography,
} from "antd";

interface Request {
  id: string;
  from: string;
  amount: number;
}

export const getRequestCols = ({
  onAction,
}: {
  onAction: (object: any) => void;
}) => {
  const { Text } = Typography;
  const RequestCols: TableProps<any>["columns"] = [
    {
      dataIndex: "user",
      title: "User",
      width: "10rem",
      ellipsis: true,
      render: (e) => {
        return <>{e.sponsor_id}</>;
      },
    },
    {
      dataIndex: "amount",
      title: "Amount",
      ellipsis: true,
    },
    {
      dataIndex: "created_at",
      title: "Request Date",
      ellipsis: true,
      render: (e) => <>{new Date(e).toLocaleDateString()}</>,
      sorter: (a, b) => {
        const aD = new Date(a.created_at).getTime();
        const bD = new Date(b.created_at).getTime();

        return aD - bD;
      },
    },
    {
      dataIndex: "user",
      title: "Bank Details",
      ellipsis: true,
      width: "25rem",
      render: (e) => (
        <Flex vertical gap={2}>
          <Text>
            <b>Account Number:</b> {e.bank_account.accNumber}
          </Text>
          <Text>
            <b>Account IFSC:</b> {e.bank_account.ifsc}
          </Text>
        </Flex>
      ),
    },
    {
      key: "action",
      title: "Action",
      width: "10rem",
      render: (_, record) => {
        return (
          <Flex justify="end">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "accept",
                    label: "Accept Request",
                    onClick: () => {
                      onAction({
                        action: "accept-request",
                        value: { id: record.id, status: "approved" },
                      });
                    },
                  },
                  {
                    key: "reject",
                    label: "Reject Request",
                    onClick: () => {
                      onAction({
                        action: "reject-request",
                        value: { id: record.id, status: "declined" },
                      });
                    },
                    danger: true,
                  },
                ],
              }}
            >
              <Button type="primary" shape="circle">
                <EllipsisOutlined
                  style={{
                    fontSize: "1.5rem",
                  }}
                />
              </Button>
            </Dropdown>
          </Flex>
        );
      },
    },
  ];
  return RequestCols;
};
