import { Button, Card, Col, Form, Input, message, Row } from "antd";
import { props } from "../../components/FieldWrapper";
import { _post } from "../../helpers/AxiosService";
import { useState } from "react";

export const UpdatePasswordComponent = () => {
  const { useForm, Item } = Form;
  const [form] = useForm();
  const [loading, isLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    try {
      const res = await _post("/update-password", { ...e });
      message.success("Updated Sucessfully");
      form.resetFields();
      isLoading(false);
    } catch {
      message.error("Invalid Credentials");
      isLoading(false);
    }
  };

  return (
    <Row align={"middle"} justify={"center"}>
      <Col xs={24} sm={18} md={14} lg={10}>
        <Card title={"Update Password"}>
          <Form
            onFinish={(e) => {
              isLoading(true);
              handleSubmit(e);
            }}
            layout="vertical"
            size="large"
            form={form}
          >
            <Item {...props("Current Password", "current_password")}>
              <Input.Password />
            </Item>
            <Item {...props("New Password", "new_password")}>
              <Input.Password />
            </Item>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
