import "../Login/index.scss";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Layout,
  message,
  Typography,
} from "antd";
import logo from "../../../assets/logo.jpeg";
import { props, RowFieldWrapper } from "../../../components/FieldWrapper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { _get,_post } from "../../../helpers/AxiosService";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { ReactComponent as Logo } from "../../../assets/footer/certified.svg";
import Trus from "../../../assets/footer/images.png";
import Trus2 from "../../../assets/footer/3.avif";
import { TrustComponent } from "../../../components/Trust";

export const SignupComponent = () => {
  const { Text, Title } = Typography;
  const { useForm, Item } = Form;
  const [form] = useForm();

  const { referId } = useParams();

  const [sponsorName, setSponsorName] = useState("");
  const [sponsored, setSponsored] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("userToken");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (referId) {
      getSponsorName(referId);
    }
  }, []);

  const getSponsorName = async (sponsorID: string) => {
    try {
      const response = await _get(`user/name/${sponsorID}`);
      if (response.data.name) {
        setSponsorName(response.data.name);
        setSponsored(true);
      }
    } catch (error) {
      setSponsorName("Invalid SPONSOR ID");
    }
  };

  const handleSponsorChange = (e: any) => {
    const value: string = e.target.value;

    if (value.length === 8) {
      getSponsorName(value.toUpperCase());
    } else {
      setSponsored(false);
      setSponsorName("");
    }
  };

  const handleSubmit = async (e: any) => {
    const {
      userName: name,
      userMobile: mobile_number,
      password,
      sponsorId: referer_id,
    } = e;

    if (password.length < 8) {
      message.error("Password should be minimum 8 characters");
      return;
    }

    if (e.sponsorId && !sponsored) {
      message.error("Invalid Sponsor ID");
      return;
    }

    setLoading(true);

    try {
      // Directly call the signup API
      const data = { name, mobile_number, password, referer_id };
      const res = await _post("/register", data);

      if (res.data.token) {
        login(res.data.token);
        navigate("/dashboard");
      }
    } catch (error: unknown) {
      if (error instanceof Error && (error as any).response && (error as any).response.data && (error as any).response.data.errors) {
        const errors = (error as any).response.data.errors;
        // Display each error message from the server
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            errors[key].forEach((msg: string) => {
              message.error(msg);
            });
          }
        }
      } else {
        message.error("Registration failed");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
      <Layout className="login-container">
        <Flex
            className="card-container"
            gap={48}
            align={"center"}
            justify={"center"}
        >
          <Col xs={24} sm={14} md={10} lg={8}>
            <Card>
              <Flex vertical gap={48}>
                <img className="logo" src={logo} alt="" />
                <Flex vertical gap={12}>
                  <Flex vertical gap={4}>
                    <Title level={3}>Sign Up</Title>
                    <Text>Enter your details to start the journey with us</Text>
                  </Flex>
                  <Form
                      onFinish={handleSubmit}
                      size="large"
                      form={form}
                      layout="vertical"
                      disabled={loading}
                      initialValues={referId ? { sponsorId: referId } : {}}
                  >
                    <RowFieldWrapper
                        children={[
                          <Item
                              name={"sponsorId"}
                              label={"Sponsor ID"}
                              key={"user-id"}
                          >
                            <Input
                                maxLength={8}
                                style={{ textTransform: "uppercase" }}
                                onChange={handleSponsorChange}
                            />
                          </Item>,
                          <Item label={"Sponsor Name"}>
                            <Input value={sponsorName} disabled />
                          </Item>,
                        ]}
                    />
                    <RowFieldWrapper
                        children={[
                          <Item {...props("Name", "userName")} key={"user-name"}>
                            <Input />
                          </Item>,
                          <Item {...props("Mobile No.", "userMobile")} key={"mbno"}>
                            <Input maxLength={10} type="number" />
                          </Item>,
                        ]}
                    />
                    <RowFieldWrapper
                        children={[
                          <Item
                              {...props("Password", "password")}
                              key={"user-password"}
                          >
                            <Input.Password />
                          </Item>,
                        ]}
                    />
                    <Item {...props("", "tnc")}>
                      <Checkbox.Group>
                        <Checkbox>I agree with the terms of use</Checkbox>
                      </Checkbox.Group>
                    </Item>
                    <Button
                        type="primary"
                        block
                        onClick={() => {
                          form.submit();
                        }}
                        loading={loading}
                    >
                      Sign Up
                    </Button>
                  </Form>
                  <Text>
                    Already have an account? <Link to={"/login"}>Log in</Link>
                  </Text>
                </Flex>
                <TrustComponent />
                <Flex align="center" justify="center" gap={12}>
                  <Logo
                      style={{
                        width: "3.25rem",
                      }}
                  />
                  <img
                      src={Trus}
                      alt=""
                      style={{
                        width: "3.25rem",
                      }}
                  />
                  <img
                      src={Trus2}
                      alt=""
                      style={{
                        width: "5.5rem",
                      }}
                  />
                </Flex>
              </Flex>
            </Card>
          </Col>
        </Flex>
      </Layout>
  );
};
