interface CardContent {
  title: string;
  search: string;
}

export const sampleData: { [key: string]: string } = {
  all_team: "12991",
  direct_team: "0",
  down_team: "0",
  level_income: "0",
  direct_income: "0",
  ad_view_income: "0",
  telegram_income: "0",
};

export const cards: CardContent[] = [
  {
    title: "Company Team",
    search: "total_users",
  },
  {
    title: "Direct Team",
    search: "direct_referrals_count",
  },
  {
    title: "Down Team",
    search: "all_level_referrals_count",
  },
  {
    title: "Level Income",
    search: "level_earnings",
  },
  {
    title: "Referral Income",
    search: "direct_income_amount",
  },
  {
    title: "Ad View Income",
    search: "task_earnings",
  },
  {
    title: "Telegram Income",
    search: "telegram_income",
  },
];
