import { Button, Flex, Layout, Typography } from "antd";
import { Props } from "../../../interfaces/headerAndSiderProps";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  MenuOutlined,
} from "@ant-design/icons";

export const HeaderComponent = ({
  collapsed,
  setCollapsed,
  zero,
  setZero,
}: Props) => {
  const { Title } = Typography;
  const { Header } = Layout;
  return (
    <Header className="app-header">
      <Flex
        gap={8}
        align="center"
        style={{
          width: "100%",
        }}
        justify="space-between"
      >
        <>
          <Title level={3}>GlobalsPay</Title>
          {zero && (
            <Button
              onClick={() => {
                setCollapsed && setCollapsed(!collapsed);
              }}
              size="small"
              type="text"
            >
              <MenuOutlined />
            </Button>
          )}
        </>
      </Flex>
    </Header>
  );
};
