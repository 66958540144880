import { Flex } from "antd";
import { ClientDisplay } from "..";

export const ClientImage = ({ image }: ClientDisplay) => {
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        margin: "0 1rem",
        backgroundColor: "rgba(0,0,0,0.05)",
        height: "5rem",
      }}
    >
      <img
        src={image}
        style={{
          width: "100%",
          objectFit: "contain",
        }}
      />
    </Flex>
  );
};
