import { type ThemeConfig, theme } from "antd";

export const lightTheme: ThemeConfig = {
  token: {
    fontFamily: "General Sans",
    borderRadius: 10,
    colorPrimary: "#ff4d4f",
  },
  components: {
    Layout: {
      siderBg: "#fff",
      bodyBg: "#fff",
      headerBg: "#fff",
      headerPadding: "0 24px",
    },
    Menu: {
      itemBg: "transparent",
      activeBarBorderWidth: 0,
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
      lineHeight: 1,
    },
    Tag: {
      marginXS: 0,
    },
    Divider: {
      marginLG: 0,
    },
  },
  algorithm: theme.defaultAlgorithm,
};
