import { useEffect, useState } from "react";
import { TableComponent } from "../../components/TableComponent";
import { columns } from "../../helpers/CommissionCols";
import { _get } from "../../helpers/AxiosService";
import { message } from "antd";

export const CommisionRecords = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await _get("/earnings");
        setData(res.data);
        setLoading(false);
      } catch {
        message.error("Something went wrong! Please refresh the page!");
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <TableComponent
        loading={loading}
        title="Total Earnings"
        datasource={data}
        columns={columns}
      />
    </>
  );
};
