import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { _get, _put } from "../../../../helpers/admin/AxiosService";
import { AxiosResponse } from "axios";

// Define TypeScript interfaces for API responses
interface Group {
  id: string;
  name: string;
}

export const UpdateTaskModal = ({
                                  onAction,
                                  open,
                                  data,
                                }: {
  onAction: (object: any) => void;
  open: boolean;
  data?: any;
}) => {
  const { Title } = Typography;
  const [form] = Form.useForm();  // Declare form here
  const [loading, isLoading] = useState(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  // Fetch groups from the API
  const fetchGroups = async () => {
    try {
      const res: AxiosResponse<Group[]> = await _get("/admin/groups");
      setGroups(res.data);
    } catch {
      message.error("Failed to fetch groups");
    }
  };

  // Fetch groups when the modal is opened
  useEffect(() => {
    if (open) {
      fetchGroups();
    }
  }, [open]);

  // Set form values and selected groups when data changes
  useEffect(() => {
    if (data) {
      // Set the form fields values
      form.setFieldsValue({
        name: data.name,
        url: data.url,
        reward: data.reward,
        youtube_video_url: data.youtube_video_url,
        groups: data.groups ? data.groups.map((group: any) => group.id) : [], // Ensure groups are preselected
      });
      // Preselect groups
      setSelectedGroups(data.groups ? data.groups.map((group: any) => group.id) : []);
    }
  }, [data, form]);

  const onSubmit = async (values: any) => {
    const { name, url, reward ,youtube_video_url} = values;
    const sentData = { ...data, name, url, reward,youtube_video_url, groups: selectedGroups };

    try {
      await _put(`/admin/tasks/${data.id}`, sentData);
      onAction({ action: "close-update", value: sentData });
      isLoading(false);
    } catch {
      message.error("Something went wrong");
      isLoading(false);
    }
  };

  return (
      <Modal closeIcon={false} footer={[]} open={open}>
        <Flex vertical gap={12}>
          <Flex justify="space-between" align="center">
            <Title level={2}>Update Task</Title>
            <Button
                type="text"
                shape="circle"
                onClick={() => {
                  onAction({ action: "close-update" });
                  form.resetFields();
                }}
            >
              <CloseCircleOutlined
                  style={{
                    fontSize: "1.5rem",
                  }}
              />
            </Button>
          </Flex>
          <Divider />
          <Form
              autoComplete="off"
              onFinish={async (values) => {
                isLoading(true);
                await onSubmit(values);
                form.resetFields();
              }}
              size="large"
              form={form}
              layout="vertical"
          >
            <Form.Item label={<Title level={5}>Task Name</Title>} name="name">
              <Input />
            </Form.Item>
            <Form.Item label={<Title level={5}>Task Link</Title>} name="url">
              <Input />
            </Form.Item>
            <Form.Item label={<Title level={5}>Task Reward</Title>} name="reward">
              <Input type="number" />
            </Form.Item>
            <Form.Item label={<Title level={5}>Youtube link</Title>} name="youtube_video_url">
              <Input />
            </Form.Item>
            <Form.Item label={<Title level={5}>Select Groups</Title>} name="groups">
              <Select
                  mode="multiple"
                  value={selectedGroups}
                  onChange={(values) => setSelectedGroups(values as string[])}
                  placeholder="Select groups"
              >
                {groups.length > 0 ? (
                    groups.map(group => (
                        <Select.Option key={group.id} value={group.id}>
                          {group.name}
                        </Select.Option>
                    ))
                ) : (
                    <Select.Option value="" disabled>
                      No groups available
                    </Select.Option>
                )}
              </Select>
            </Form.Item>
            <Button
                onClick={() => {
                  form.submit();
                }}
                block
                type="primary"
                loading={loading}
                disabled={loading}
            >
              Update <CheckCircleOutlined />
            </Button>
          </Form>
        </Flex>
      </Modal>
  );
};
