import { Flex, message, TableProps, Tag, Typography } from "antd";
import { TableComponent } from "../../components/TableComponent";
import { NetworkTableProps } from "../../interfaces/tableProps";
import { columns } from "../../helpers/NetworkCols";
import { useEffect, useState } from "react";
import { _get } from "../../helpers/AxiosService";

export const DirectNetworkComponent = () => {
  const [data, setData] = useState<NetworkTableProps[]>([]);
  const [loading, isLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await _get("/referrals/direct");
        setData(res.data.direct_referrals);
        isLoading(false);
      } catch {
        message.error("Some error occured! Please refresh the page!");
      }
    };

    fetch();
  }, []);

  return (
    <TableComponent
      title="Direct Team"
      loading={loading}
      datasource={data}
      columns={columns}
    />
  );
};
