import React from 'react';
import { TableProps, Button, Space } from 'antd';

interface Users {
  id: string;
  name: string;
  directTeam: number;
  allTeam: number;
  taskCompleted: number;
  isApproved: boolean;
}

interface UserColsProps {
  onAction: (object: { action: string; value?: { userId: string } }) => void;
}

export const UserCols = ({ onAction }: UserColsProps): TableProps<Users>['columns'] => [
  {
    dataIndex: 'sponsor_id',
    title: 'ID',
    responsive: ['md'],
    width: '10rem',
    ellipsis: true,
  },
  {
    dataIndex: 'name',
    title: 'Name',
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    dataIndex: 'mobile_number',
    title: 'Mobile Number',
    ellipsis: true,
  },
  {
    dataIndex: 'referer_id',
    title: 'Referer',
    ellipsis: true,
  },
  {
    dataIndex: 'balance',
    title: 'Balance',
    ellipsis: true,
    sorter: (a, b) => a.allTeam - b.allTeam,
  },
  {
    dataIndex: 'action',
    title: 'Action',
    ellipsis: true,
    render: (_, record) => (
        <Space>
          <Button onClick={() => onAction({ action: 'open-drawer', value: { userId: record.id } })}>
            View Task
          </Button>
        </Space>
    ),
  },
];
